import React from 'react';

const Download = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_50_3636)'>
        <path
          d='M31.6667 15H25V5H15V15H8.33334L20 26.6667L31.6667 15ZM18.3333 18.3333V8.33333H21.6667V18.3333H23.6167L20 21.95L16.3833 18.3333H18.3333ZM8.33334 30H31.6667V33.3333H8.33334V30Z'
          fill='#2c2d2c'
        />
      </g>
      <defs>
        <clipPath id='clip0_50_3636'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Download;
