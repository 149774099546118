import Ato from './pages/Ato/Ato';
import CheckAto from './pages/CheckAto/CheckAto';
import Home from './pages/Home/Home';
import Payment from './pages/Payment/Payment';
import Register from './pages/Register/Register';
import Students from './pages/Students/Students';

export const routes = [
  {
    id: 1,
    path: '/',
    component: Home,
  },
  {
    id: 2,
    path: '/register',
    component: Register,
  },
  {
    id: 3,
    path: '/students-list',
    component: Students,
  },
  {
    id: 4,
    path: '/ato',
    component: Ato,
  },

  {
    id: 5,
    path: '/payment',
    component: Payment,
  },
];
