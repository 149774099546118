import * as services from '../../services/atoService';
import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';

//auth dispatch
export const getStart = () => {
  return {
    type: actionTypes.GET_ATO_START,
  };
};

export const getFail = (error) => {
  return {
    type: actionTypes.GET_ATO_FAIL,
    payload: {
      error,
    },
  };
};

export const getSuccess = (data) => {
  return {
    type: actionTypes.GET_ATO_SUCCESS,
    payload: {
      data,
    },
  };
};

//actions
export const getAtoRequests = () => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.getAtoRequests();
      dispatch(getSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch students list'
      );
      dispatch(getFail(error.response.data.data.error));
    }
  };
};
