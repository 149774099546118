import './register.scss';
import { AuthLayout } from '../../components/AuthLayout/AuthLayout';
import RadioChecked from '../../icons/RadioChecked';
import { useEffect, useState } from 'react';
import RadioUnchecked from '../../icons/RadioUnchecked';
import SchoolInformation from './SchoolInformation';
import ContactDetails from './ContactDetails';
import Summary from './Summary';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';

const Register = () => {
  let query = new URLSearchParams(useLocation().search);
  let tab = query.get('tab');
  const navigate = useNavigate();

  const [active, setActive] = useState(tab ? tab : 'information');

  const moveToNextStep = (step) => {
    setActive(step);
    navigate(`/register?=${step}`);
  };

  useEffect(() => {
    setActive(tab ? tab : 'information');
  }, []);

  let components = {
    information: <SchoolInformation moveToNextStep={moveToNextStep} />,
    contact: <ContactDetails moveToNextStep={moveToNextStep} />,
    summary: <Summary moveToNextStep={moveToNextStep} />,
  };

  return (
    <AuthLayout
      subtext={
        'Register your school information to get started on your account'
      }
      currentPath='Register'
    >
      <div className='register__heading'>
        <div
          className={
            active === 'information'
              ? 'register__heading__title active-heading'
              : 'register__heading__title'
          }
          //  onClick={() => setActive('information')}
        >
          {active === 'information' ? <RadioChecked /> : <RadioUnchecked />}
          <p>School information</p>
        </div>

        <div
          className={
            active === 'contact'
              ? 'register__heading__title active-heading'
              : 'register__heading__title'
          }
          // onClick={() => setActive('contact')}
        >
          {active === 'contact' ? <RadioChecked /> : <RadioUnchecked />}
          <p>Contact details</p>
        </div>

        <div
          className={
            active === 'summary'
              ? 'register__heading__title active-heading'
              : 'register__heading__title'
          }
          // onClick={() => setActive('summary')}
        >
          {active === 'summary' ? <RadioChecked /> : <RadioUnchecked />}
          <p>Summary</p>
        </div>
      </div>
      <div>{components[active]}</div>
    </AuthLayout>
  );
};

export default Register;
