export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const UPDATE_ORDER_START = 'UPDATE_ORDER_START';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

export const GET_STUDENTS_START = 'GET_STUDENTS_START';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAIL = 'GET_STUDENTS_FAIL';

export const GET_ATO_START = 'GET_ATO_START';
export const GET_ATO_SUCCESS = 'GET_ATO_SUCCESS';
export const GET_ATO_FAIL = 'GET_ATO_FAIL';

export const GET_STATS_START = 'GET_STATS_START';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_FAIL = 'GET_STATS_FAIL';

export const GENERATE_SEMAAS_START = 'GENERATE_SEMAAS_START';
export const GENERATE_SEMAAS_SUCCESS = 'GENERATE_SEMAAS_SUCCESS';
export const GENERATE_SEMAAS_FAIL = 'GENERATE_SEMAAS_FAIL';

export const PRINT_ID_START = 'PRINT_ID_START';
export const PRINT_ID_SUCCESS = 'PRINT_ID_SUCCESS';
export const PRINT_ID_FAIL = 'PRINT_ID_FAIL';

export const SEARCH_SCHOOLS_START = 'SEARCH_SCHOOLS_START';
export const SEARCH_SCHOOLS_SUCCESS = 'SEARCH_SCHOOLS_SUCCESS';
export const SEARCH_SCHOOLS_FAIL = 'SEARCH_SCHOOLS_FAIL';

//modal
export const OPEN_AUTH_MODAL = 'OPEN_SUB_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_SUB_MODAL';
