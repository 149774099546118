import axios from '../utils/axios';

export const signupSchool = async (data) => {
  const response = await axios.post('/school', data);
  return response.data;
};

export const login = async (data) => {
  const response = await axios.post('/user/login', data);
  return response.data;
};

export const logout = async () => {
  localStorage.removeItem('token');
  localStorage.clear();
  window.location.replace('/');
};
