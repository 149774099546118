import './header.scss';
import { useState } from 'react';
import logo from '../../assets/logo-new.jpg';
import { Link, useNavigate } from 'react-router-dom';
import Expand from '../../icons/Expand';
import LoginModal from '../Modals/LoginModal/LoginModal';
import { openModal } from '../../redux/actions/utilsActions';
import { useDispatch } from 'react-redux';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

const Header = ({ type, page }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let token = localStorage.getItem('token');
  const [show, setShow] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(true);

  const logout = () => {
    localStorage.clear();
    window.location.replace('/');
  };

  const open = () => {
    dispatch(openModal());
    setShowMobileNav(true);
  };

  return (
    <nav className={type === 'primary' ? 'nav' : 'nav-secondary'}>
      <div onClick={() => navigate('/')}>
        <img src={logo} alt='logo' className='header-logo' />{' '}
      </div>

      <div
        className={
          type === 'primary' ? 'right web-nav' : 'right-secondary web-nav'
        }
      >
        {/* <Link
          to={!token ? '#' : '/students-list'}
          onClick={() => (!token ? dispatch(openModal()) :  setShowMobileNav(false))}
        >
          Print ID Card
        </Link> */}
        <Link
          to={!token ? '#' : '/students-list'}
          onClick={() =>
            !token ? dispatch(openModal()) : setShowMobileNav(false)
          }
        >
          Generate SEMAAS number
        </Link>
        {/* <Link
          to={!token ? '#' : '/ato'}
          onClick={() =>
            !token ? dispatch(openModal()) : setShowMobileNav(false)
          }
        >
          Print ATO
        </Link> */}
        {token ? (
          <div className='logo-action-wrapper'>
            <div className='logo-action' onClick={() => setShow(!show)}>
              <img src={logo} alt='' />
              <Expand color={page === 'home' ? '#fff' : '#323232'} />
            </div>

            {show && (
              <div className='drop'>
                <p
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            <a
              href='#'
              rel='noreferrer'
              onClick={() => {
                setShowMobileNav(true);
                dispatch(openModal());
              }}
            >
              Login
            </a>
            {!showMobileNav ? (
              <button
                className={'mobile-reg-btn'}
                onClick={() => {
                  navigate('/register');
                }}
              >
                Register
              </button>
            ) : (
              <button
                className={
                  type === 'primary' ? 'right__btn' : 'right-secondary__btn'
                }
                onClick={() => {
                  navigate('/register');
                }}
              >
                Register
              </button>
            )}
          </>
        )}
      </div>
      <div
        className={
          type === 'primary'
            ? `${showMobileNav && 'nav-hide'} right`
            : `${showMobileNav && 'nav-hide'} right-secondary`
        }
      >
        {/* <Link
          to={!token ? '#' : '/students-list'}
          onClick={() => (!token ? open() : setShowMobileNav(false))}
        >
          Print ID Card
        </Link> */}
        <Link
          to={!token ? '#' : '/students-list'}
          onClick={() => (!token ? open() : setShowMobileNav(false))}
        >
          Generate SEMAAS number
        </Link>
        {/* <Link
          to={!token ? '#' : '/ato'}
          onClick={() => (!token ? open() : setShowMobileNav(false))}
        >
          Print ATO
        </Link> */}
        {token ? (
          <div className='logo-action-wrapper'>
            <div className='logo-action' onClick={() => setShow(!show)}>
              <img src={logo} alt='' />
              <Expand color='#fff' />
            </div>

            {show && (
              <div className='drop'>
                <p
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            <a
              href='#'
              rel='noreferrer'
              onClick={() => {
                setShowMobileNav(true);
                dispatch(openModal());
              }}
            >
              Login
            </a>
            {showMobileNav ? (
              <button
                className={
                  type === 'primary' ? 'right__btn' : 'right-secondary__btn'
                }
                onClick={() => {
                  navigate('/register');
                }}
              >
                Register
              </button>
            ) : (
              <button
                className={'mobile-reg-btn'}
                onClick={() => {
                  navigate('/register');
                }}
              >
                Register
              </button>
            )}
          </>
        )}
      </div>
      <div className='bars' onClick={() => setShowMobileNav(!showMobileNav)}>
        {!showMobileNav ? (
          <AiOutlineClose color={type === 'primary' ? '#fff' : '#4b8e64'} />
        ) : (
          <FaBars color={type === 'primary' ? '#fff' : '#4b8e64'} />
        )}
      </div>
      <LoginModal />
    </nav>
  );
};

export default Header;
