import { toast } from 'react-hot-toast';
import { checkrecords } from '../services/utilsService';

export const toastComingSoon = (text) => {
  toast(text, {
    duration: 4000,
    position: 'top-center',

    // Styling
    style: {},
    className: '',

    // Custom Icon
    icon: 'ℹ️',

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },

    // Aria
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const emailReg =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const capitalize = (str) => {
  const arr = str?.split(' ');

  for (var i = 0; i < arr?.length; i++) {
    arr[i] = arr[i]?.charAt(0)?.toUpperCase() + arr[i]?.slice(1);
  }

  const str2 = arr?.join(' ');
  return str2;
};

export const lettersOnly = (str) => {
  return /^[A-Za-z\s]*$/.test(str);
};

export const numbersOnly = (str) => {
  return /^[0-9]*$/.test(str);
};

export const validateRecords = async (data, fieldname) => {
  try {
    await checkrecords(data);
    return 'pass';
  } catch (error) {
    // console.log(error.response.data.data.errors);
    // toast.error('error');
    return error.response.data.data.errors[fieldname];
  }
};
