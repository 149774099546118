import React from 'react';
import ForwardArrow from '../../icons/ForwardArrow';
import logo from '../../assets/logo-new.jpg';
import './authlayout.scss';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import { useNavigate } from 'react-router';

export const AuthLayout = ({ subtext, currentPath, children }) => {
  const navigate = useNavigate();
  return (
    <div className='auth-wrapper'>
      <div className='auth-wrapper__left'>
        <h2>
          Easily manage all your <br /> school processes and <br /> activities
        </h2>
        <div className='auth-wrapper__left__text'>
          <ForwardArrow />
          <p>
            School Staff can use Edves to manage all activities from <br />
            enrollment to attendance, calendar, timetable, growth <br />{' '}
            tracking, student records, scheduling tests/ exams, <br /> sharing
            assignments, class notes...
          </p>
        </div>
      </div>
      <div className='auth-wrapper__right'>
        <BreadCrumbs
          prev={'Home'}
          present={currentPath}
          goToPrev={() => navigate(-1)}
          goToPage={() => navigate('/')}
        />
        <div className='auth-wrapper__right__content'>
          <img src={logo} alt='logo' className='header-logo' />
          <h3>
            Cross River State Education Management and Administration Automation
            System
          </h3>
          <p>{subtext}</p>
        </div>

        {children}
      </div>
    </div>
  );
};
