import * as actionTypes from '../actions/actionTypes';

const initialState = {
  students: {},
  error: null,
  loading: false,
  generateLoading: false,
  idCardLoading: false,
  stats: {},
  statsLoading: false,
};

const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDENTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_STUDENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case actionTypes.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload.data,
        loading: false,
        error: null,
      };

    case actionTypes.GENERATE_SEMAAS_START:
      return {
        ...state,
        generateLoading: true,
        error: null,
      };

    case actionTypes.GENERATE_SEMAAS_FAIL:
      return {
        ...state,
        generateLoading: false,
        error: action.payload.error,
      };

    case actionTypes.GENERATE_SEMAAS_SUCCESS:
      return {
        ...state,
        generateLoading: false,
        error: null,
      };

    case actionTypes.PRINT_ID_START:
      return {
        ...state,
        idCardLoading: true,
        error: null,
      };

    case actionTypes.PRINT_ID_FAIL:
      return {
        ...state,
        idCardLoading: false,
        error: action.payload.error,
      };

    case actionTypes.PRINT_ID_SUCCESS:
      return {
        ...state,
        idCardLoading: false,
        error: null,
      };

    case actionTypes.GET_STATS_START:
      return {
        ...state,
        statsLoading: true,
        error: null,
      };

    case actionTypes.GET_STATS_FAIL:
      return {
        ...state,
        statsLoading: false,
        error: action.payload.error,
      };

    case actionTypes.GET_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload.data,
        statsLoading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default studentsReducer;
