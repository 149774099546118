import axios from '../utils/axios';

let user = JSON.parse(localStorage.getItem('user'));

export const getStudents = async (page, filter) => {
  const response = filter
    ? await axios.get(
        `/schools/${user?.school.id}/students?page=${page}&filter=${filter}`
      )
    : await axios.get(`/schools/${user?.school.id}/students?page=${page}`);
  return response.data;
};

export const getStats = async () => {
  const response = await axios.get(
    `/schools/${user?.school.id}/students/identity/stats`
  );

  return response.data;
};

export const generateSemaas = async (data) => {
  const response = await axios.post(
    `/schools/${user?.school.id}/students/identity`,
    data
  );
  return response.data;
};

export const printIdCard = async (data) => {
  const response = await axios.post(
    `/schools/${user?.school.id}/students/identity-card`,
    data
  );
  return response.data;
};
