import axios from 'axios';
import { baseURL } from '../utils/axios';

export const createArm = async (id, data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  };
  const response = await axios.put(
    `${baseURL}/schools/${id}/arm`,
    data,
    config
  );
  return response.data;
};

export const getStock = async (id, token, param) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  };
  const response = await axios.get(
    `${baseURL}/schools/${id}/stock?bill=${param}`,
    config
  );
  return response.data;
};

export const checkrecords = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer `,
      'Content-Type': 'application/json; charset=utf-8',
    },
  };
  const response = await axios.post(`${baseURL}/check-records`, data, config);
  return response.data;
};

export const searchForSchool = async (params) => {
  const config = {
    headers: {
      Authorization: `Bearer `,
      'Content-Type': 'application/json; charset=utf-8',
    },
  };
  const response = await axios.get(
    `${baseURL}/search?school=${params}`,
    config
  );
  return response.data;
};
