import React from 'react';

const Register = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_38_3587)'>
        <path
          d='M21.6667 5H18.3333V21.6667H21.6667V5ZM29.7167 8.61667L27.35 10.9833C29.9833 13.1 31.6667 16.35 31.6667 20C31.6667 26.45 26.45 31.6667 20 31.6667C13.55 31.6667 8.33333 26.45 8.33333 20C8.33333 16.35 10.0167 13.1 12.6333 10.9667L10.2833 8.61667C7.05 11.3667 5 15.4333 5 20C5 28.2833 11.7167 35 20 35C28.2833 35 35 28.2833 35 20C35 15.4333 32.95 11.3667 29.7167 8.61667Z'
          fill='#2c2d2c'
        />
      </g>
      <defs>
        <clipPath id='clip0_38_3587'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Register;
