import React from 'react';

const Login = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_49_3626)'>
        <path
          d='M18.3333 11.6667L16 14L20.3333 18.3333H3.33334V21.6667H20.3333L16 26L18.3333 28.3333L26.6667 20L18.3333 11.6667ZM33.3333 31.6667H20V35H33.3333C35.1667 35 36.6667 33.5 36.6667 31.6667V8.33333C36.6667 6.5 35.1667 5 33.3333 5H20V8.33333H33.3333V31.6667Z'
          fill='#2c2d2c'
        />
      </g>
      <defs>
        <clipPath id='clip0_49_3626'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Login;
