import React from 'react';

const Eye = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_175_4534)'>
        <path
          d='M20 9.99984C26.3167 9.99984 31.95 13.5498 34.7 19.1665C31.95 24.7832 26.3167 28.3332 20 28.3332C13.6833 28.3332 8.05 24.7832 5.3 19.1665C8.05 13.5498 13.6833 9.99984 20 9.99984ZM20 6.6665C11.6667 6.6665 4.55 11.8498 1.66667 19.1665C4.55 26.4832 11.6667 31.6665 20 31.6665C28.3333 31.6665 35.45 26.4832 38.3333 19.1665C35.45 11.8498 28.3333 6.6665 20 6.6665ZM20 14.9998C22.3 14.9998 24.1667 16.8665 24.1667 19.1665C24.1667 21.4665 22.3 23.3332 20 23.3332C17.7 23.3332 15.8333 21.4665 15.8333 19.1665C15.8333 16.8665 17.7 14.9998 20 14.9998ZM20 11.6665C15.8667 11.6665 12.5 15.0332 12.5 19.1665C12.5 23.2998 15.8667 26.6665 20 26.6665C24.1333 26.6665 27.5 23.2998 27.5 19.1665C27.5 15.0332 24.1333 11.6665 20 11.6665Z'
          fill='#010C15'
        />
      </g>
      <defs>
        <clipPath id='clip0_175_4534'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Eye;
