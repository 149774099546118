import { useState } from 'react';
import Modal from 'react-modal';
import './loginmodal.scss';
import logo from '../../../assets/logo-new.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { closeModal } from '../../../redux/actions/utilsActions';
import { Cancel } from '../../../icons/Cancel';
import TextInput from '../../Input/TextInput';
import Button from '../../Button/Button';
import { loginUser } from '../../../redux/actions/authActions';
import { emailReg } from '../../../utils/utils';

const LoginModal = ({ ato }) => {
  const dispatch = useDispatch();

  Modal.setAppElement('#root');

  const { modalOpen } = useSelector((state) => state?.modal);
  const { loading } = useSelector((state) => state.auth);

  const closeSubModal = () => {
    dispatch(closeModal());
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',

      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '600px',
    },
  };

  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleBlur = (evt) => {
    const value = evt.target.value;
    if (!value) {
      setErrors({
        ...errors,
        [evt.target.name]: `Field cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [evt.target.name]: ``,
      });
    }
  };

  const pushRoute = () => {
    setState({
      username: '',
      password: '',
    });
    dispatch(closeModal());

    // navigate('/students-list');
    window.location.replace(ato ? '/payment' : '/students-list');
  };

  const submit = () => {
    let data;
    if (emailReg.test(state.username)) {
      data = {
        email: state.username,
        password: state.password,
      };
    } else {
      data = {
        username: state.username,
        password: state.password,
      };
    }

    dispatch(loginUser(data, pushRoute));
  };

  return (
    <Modal
      isOpen={modalOpen}
      style={customStyles}
      onRequestClose={closeSubModal}
    >
      <div className='modal-cancel' onClick={() => closeSubModal()}>
        <Cancel />
      </div>

      <div className='login-modal'>
        <div className='login-modal__heading'>
          <img src={logo} alt='' className='header-logo' />
          <h2>
            Cross River State Education Management and Administration Automation
            System
          </h2>
          <p>Login as a school staff to access your account</p>
        </div>

        <TextInput
          label='Username/ Email Address'
          fieldClass={errors['username'] ? 'error-field' : 'input-field'}
          placeholder='edves123 or edves1@gmail.com'
          name='username'
          value={state.username}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type='text'
          errorMessage={errors['username']}
          errorClass={'error-msg'}
        />

        <TextInput
          label='Password*'
          fieldClass={errors['password'] ? 'error-field' : 'input-field'}
          placeholder='Set a password for your school login'
          name='password'
          value={state.password}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type='password'
          errorMessage={errors['password']}
          errorClass={'error-msg'}
        />

        <Button
          btnClass={'reg-button'}
          btnText={loading ? 'Please wait...' : 'Log In'}
          onClick={() => {
            submit();
          }}
          disabled={state.username === '' || state.password === '' || loading}
        />
      </div>
    </Modal>
  );
};

export default LoginModal;
