import { useState } from 'react';
import Button from '../../components/Button/Button';
import TextInput from '../../components/Input/TextInput';
import ArrowBackward from '../../icons/ArrowBackward';
import ArrowForward from '../../icons/ArrowForward';
import {
  lettersOnly,
  validateRecords,
  emailReg,
  numbersOnly,
} from '../../utils/utils';

const ContactDetails = ({ moveToNextStep }) => {
  let contactDetails = JSON.parse(localStorage.getItem('contactDetails'));
  const [state, setState] = useState({
    fullname: contactDetails?.fullname || '',
    email: contactDetails?.email || '',
    gender: contactDetails?.gender || '',
    phoneNumber: contactDetails?.phoneNumber || '',
    username: contactDetails?.username || '',
    password: contactDetails?.password || '',
    confirmPassword: contactDetails?.confirmPassword || '',
  });

  const [errors, setErrors] = useState({
    email: '',
    phoneNumber: '',
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [validationLoading, setValidationLoading] = useState(false);
  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  //validation on blur
  const handleBlur = async (evt) => {
    const value = evt.target.value;

    if (value) {
      setValidationLoading(true);
      let res = await validateRecords({ [evt.target.name]: value }, [
        evt.target.name,
      ]);
      setValidationLoading(false);

      if (res !== 'pass') {
        setValidationLoading(false);
        setErrors({
          ...errors,
          [evt.target.name]: res[0],
        });

        return;
      }
    }

    if (evt.target.dataset.validation === 'textonly') {
      if (lettersOnly(value) === false) {
        setErrors({
          ...errors,
          [evt.target.name]: `Field can only contain letters`,
        });
        return;
      }
    }

    const init = () => {
      setErrors({
        ...errors,
        [evt.target.name]: ``,
      });
    };

    if (!value) {
      setErrors({
        ...errors,
        [evt.target.name]: `Field cannot be empty`,
      });
      return;
    }

    if (evt.target.name === 'email' && value !== '') {
      let res = emailReg.test(value);
      if (res === false) {
        setErrors({
          ...errors,
          [evt.target.name]: `Invalid email`,
        });

        return;
      } else {
        init();
      }
    } else {
      init();
    }

    if (evt.target.name === 'password' && value !== '') {
      if (value?.length < 8) {
        setErrors({
          ...errors,
          [evt.target.name]: `Password cannot be less than 8 characters`,
        });
        return;
      }
    }

    //

    if (evt.target.name === 'phoneNumber' && value !== '') {
      if (numbersOnly(value) === false) {
        setErrors({
          ...errors,
          [evt.target.name]: `Field can only contain numbers`,
        });
        return;
      }
      if (value?.length < 8) {
        setErrors({
          ...errors,
          [evt.target.name]: `Field cannot be less than 8 numbers`,
        });
        return;
      }
    }

    if (evt.target.name === 'confirmPassword' && value !== '') {
      if (value !== state.password) {
        setErrors({
          ...errors,
          [evt.target.name]: `Passwords do not match`,
        });
        return;
      } else {
        init();
      }
    } else {
      init();
    }
  };

  const selectValue = (option, name) => {
    setState({ ...state, [name]: option });
  };
  return (
    <div>
      <TextInput
        label='Full Name* (Firstname Lastname)'
        fieldClass={errors['fullname'] ? 'error-field' : 'input-field'}
        placeholder='Enter your full name'
        name='fullname'
        value={state.fullname}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='text'
        errorMessage={errors['fullname']}
        errorClass={'error-msg'}
        validationType={'textonly'}
      />
      <TextInput
        label='Email Address*'
        fieldClass={errors['email'] ? 'error-field' : 'input-field'}
        placeholder='Enter schools email address'
        name='email'
        value={state.email}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='email'
        errorMessage={errors['email']}
        errorClass={'error-msg'}
      />

      <TextInput
        label='Mobile Number*'
        fieldClass={errors['phoneNumber'] ? 'error-field' : 'input-field'}
        placeholder='Input schools mobile number'
        name='phoneNumber'
        value={state.phoneNumber}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='text'
        errorMessage={errors['phoneNumber']}
        errorClass={'error-msg'}
      />

      <TextInput
        label='Username*'
        fieldClass={errors['username'] ? 'error-field' : 'input-field'}
        placeholder='Enter a username for your school login'
        name='username'
        value={state.username}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='text'
        errorMessage={errors['username']}
        errorClass={'error-msg'}
      />

      <TextInput
        label='Gender*'
        placeholder='Select gender'
        name='gender'
        value={state.gender}
        onSelectValue={selectValue}
        type='dropdown'
        options={[
          {
            id: 1,
            title: 'Female',
          },
          {
            id: 2,
            title: 'Male',
          },
        ]}
        errorClass={'error-msg'}
      />

      <TextInput
        label='Password*'
        fieldClass={errors['password'] ? 'error-field' : 'input-field'}
        placeholder='Set a password for your school login'
        name='password'
        value={state.password}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='password'
        errorMessage={errors['password']}
        errorClass={'error-msg'}
      />

      <TextInput
        label='Confirm Password*'
        fieldClass={errors['confirmPassword'] ? 'error-field' : 'input-field'}
        placeholder='Confirm Password'
        name='confirmPassword'
        value={state.confirmPassword}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='password'
        errorMessage={errors['confirmPassword']}
        errorClass={'error-msg'}
      />

      <div className='double-btn-flex'>
        <Button
          onClick={() => {
            moveToNextStep('information');
          }}
          btnClass={'secondary-button'}
          btnText={
            <div className='btn-flex'>
              <ArrowBackward />
              <p>Go Back</p>
            </div>
          }
        />
        <Button
          onClick={() => {
            localStorage.setItem('contactDetails', JSON.stringify(state));
            // moveToNextStep('summary');
            window.location.replace('/register?tab=summary');
          }}
          btnClass={'primary-button'}
          btnText={
            <div className='btn-flex'>
              <p>{validationLoading ? 'Please wait' : 'Proceed'}</p>
              <ArrowForward />
            </div>
          }
          disabled={
            errors.email ||
            state.email === '' ||
            state.phoneNumber === '' ||
            errors.phoneNumber ||
            errors.username ||
            errors.password ||
            errors.confirmPassword ||
            state.username === '' ||
            state.password === '' ||
            state.confirmPassword === '' ||
            errors.email ||
            errors.phoneNumber ||
            errors.password ||
            errors.confirmPassword ||
            state.gender === ''
          }
        />
      </div>
    </div>
  );
};

export default ContactDetails;
