import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';
import * as services from '../../services/utilsService';

export const openSuccess = (data) => {
  return {
    type: actionTypes.OPEN_AUTH_MODAL,
    payload: {
      data,
    },
  };
};

export const closeSuccess = (data) => {
  return {
    type: actionTypes.CLOSE_AUTH_MODAL,
    payload: {
      data,
    },
  };
};

export const openModal = () => {
  return (dispatch) => {
    dispatch(openSuccess(true));
  };
};

export const closeModal = () => {
  return (dispatch) => {
    dispatch(closeSuccess(false));
  };
};

//auth dispatch
export const searchStart = () => {
  return {
    type: actionTypes.SEARCH_SCHOOLS_START,
  };
};

export const searchFail = (error) => {
  return {
    type: actionTypes.SEARCH_SCHOOLS_FAIL,
    payload: {
      error,
    },
  };
};

export const searchSuccess = (data) => {
  return {
    type: actionTypes.SEARCH_SCHOOLS_SUCCESS,
    payload: {
      data,
    },
  };
};

//actions
export const searchForStudents = (params) => {
  return async (dispatch) => {
    dispatch(searchStart());
    try {
      const response = await services.searchForSchool(params);
      dispatch(searchSuccess(response.data));
    } catch (error) {
      dispatch(searchFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch students list'
      );
      dispatch(searchFail(error.response.data.data.error));
    }
  };
};
