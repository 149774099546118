import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Header from '../../components/Header/Header';
import empty from '../../assets/empty-students.svg';
import moment from 'moment';
import idcardback from '../../assets/id-card-back.png';
import { useReactToPrint } from 'react-to-print';

import './students.scss';
import Button from '../../components/Button/Button';
import Person from '../../icons/Person';
import Microwave from '../../icons/Microwave';
import Profile from '../../icons/Profile';
import PersonRemove from '../../icons/PersonRemove';
import PersonAdd from '../../icons/PersonAdd';
import Table from '../../components/Table/Table';
import SuccessModal from '../../components/Modals/SuccessModal/SuccessModal';
import IDCardModal from '../../components/Modals/IdCardModal/IDCardModal';
import CreateModalPassword from '../../components/Modals/CreatePasswordModal/CreateModalPassword';
import { useDispatch, useSelector } from 'react-redux';
import { getStudents, getStats } from '../../redux/actions/studentsActions';
import * as utilsServices from '../../services/utilsService';
import Spinner from '../../components/Spinner/Spinner';
import {
  generateSemaas,
  generateIdCard,
} from '../../redux/actions/studentsActions';
import Pagination from '../../components/Pagination/Pagination';

const Students = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const dispatch = useDispatch();
  const {
    loading,
    students,
    generateLoading,
    idCardLoading,
    statsLoading,
    stats,
  } = useSelector((state) => state.students);
  const {
    totalStudents,
    stockLeft,
    studentsWithoutIdentity,
    studentsWithIdentity,
  } = stats;

  const [activeFilter, setActiveFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [idCardPayment, setIdCardPayment] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [stock, setStock] = useState(0);
  const [printLoading, setPrintLoading] = useState(false);
  const [singleStudent, setSingleStudent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const closeModal = () => {
    setShowModal(false);
    setIdCardPayment(false);
  };

  const openModal = () => {
    setShowModal(true);
  };
  const openIdCardPayment = () => {
    setIdCardPayment(true);
  };
  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const openPasswordModal = () => {
    setShowPasswordModal(true);
  };

  const getStock = async (param) => {
    try {
      let res = await utilsServices.getStock(user?.school?.id, token, param);

      let total = res.data.stock.reduce(function (acc, obj) {
        return acc + obj.units_left;
      }, 0);

      setStock(total);

      return total;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && user?.school?.activated === false) {
      navigate('/payment');
    }
  }, []);

  const print = (value) => {
    setSingleStudent(value);
    setTimeout(() => {
      setPrintLoading(true);
      handlePrint();
      setPrintLoading(false);
    }, 2000);
  };

  useEffect(() => {
    dispatch(getStudents(currentPage));
    getStock(2);
    dispatch(getStats());
  }, [currentPage]);

  const refreshData = () => {
    dispatch(getStudents(currentPage));
    getStock(2);
    dispatch(getStats());
  };

  //table headers
  const columns = [
    {
      Header: '  ',
      accessor: (d) => `${d?.id}`,
      Cell: ({ cell: { value } }) => (
        <input
          name='id'
          type='checkbox'
          checked={selectedStudents.includes(value)}
          onChange={(e) => handleCheckbox(e, value)}
        />
      ),
    },
    {
      Header: 'STUDENT NAME',
      accessor: (d) => `${d?.first_name} ${d?.last_name}`,
      Cell: ({ cell: { value } }) => <p> {value}</p>,
    },

    {
      Header: 'STUDENT SEMAAS NUM',
      accessor: 'identity',
      Cell: ({ cell: { value } }) => <p> {value ? value?.number : 'N/A'}</p>,
    },

    {
      Header: 'SEMAAS No. STATUS',
      accessor: (d) => `${d?.identity ? 'Generated' : 'Not Generated'}`,
      Cell: ({ cell: { value } }) => <Badge value={value} />,
    },

    {
      Header: 'ID CARD STATUS',
      accessor: (d) => `${d?.identity_card ? 'Generated' : 'Not Generated'}`,
      Cell: ({ cell: { value } }) => <Badge value={value} />,
    },

    {
      Header: 'DATE GENERATED',
      accessor: (d) => moment(d?.identity?.created_at).format('lll'),
      Cell: ({ cell: { value } }) => <p> {value}</p>,
    },

    {
      Header: 'Actions',
      accessor: (d) => <GenerateButton value={d} />,
      Cell: ({ cell: { value } }) => value,
    },
  ];

  //handle checkbox change
  const handleCheckbox = (e, id) => {
    if (e?.target?.checked) {
      //append to array
      setSelectedStudents(selectedStudents.concat([id]));
    } else {
      //remove from array
      setSelectedStudents(
        selectedStudents.filter(function (val) {
          return val !== id;
        })
      );
    }
  };

  const Badge = ({ value }) => {
    return (
      <div
        className={
          value.toLowerCase() === 'generated'
            ? 'generated-badge'
            : 'not-generated-badge'
        }
      >
        {value}
      </div>
    );
  };

  const open = () => {
    openIdCardPayment();
  };

  const GenerateButton = ({ value }) => {
    return (
      <>
        {value?.identity_card ? (
          <div
            className='generate-flex'
            onClick={() => {
              print(value);
            }}
          >
            <PersonAdd />
            <p>Print ID Card</p>
          </div>
        ) : value?.identity ? (
          <div
            className='generate-flex'
            onClick={() => {
              dispatch(generateIdCard({ students: [value.id] }, open));
            }}
          >
            <PersonAdd />
            <p>{idCardLoading ? 'Please wait...' : 'Generate ID Card'}</p>
          </div>
        ) : (
          <div
            className='generate-flex'
            onClick={() => dispatch(generateSemaas({ students: [value.id] }))}
          >
            <PersonAdd />
            <p>{generateLoading ? 'Generating...' : 'Assign SEMAAS Num'}</p>
          </div>
        )}
      </>
    );
  };

  const emptyState = () => {
    return (
      <div className='empty-students'>
        <div className='empty-students__title'>
          <h2>{user?.school?.name || ''}</h2>
          <p>
            Select the number of students you want to generate SEMAAS number for
          </p>
          <div className='empty-students__body'>
            <img src={empty} alt='empty state' />
            <h4>No Generated SEMAAS Num</h4>
            <p>
              You currently do not have any student on your list, please go to
              your edves portal to upload your student list
            </p>
            <div className='empty-students__body__btn'>
              <Button
                onClick={() =>
                  !user
                    ? window.location.replace('/')
                    : user?.school?.arm === null
                    ? openPasswordModal()
                    : window.open('https://crsemaas.edves.net', '_blank')
                }
                btnClass={'empty-button'}
                btnText={
                  <div className='btn-flex'>
                    <Person />
                    <p>Go to portal</p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const view = () => {
    return (
      <div className='student-list'>
        <div className='student-list__heading'>
          <div className='student-list__heading__left'>
            <h3>Student Information</h3>
            <p>
              Select the number of students you want to generate SEMAAS number
              for
            </p>
          </div>
          <div className='student-list__heading__right'>
            {user && user?.school?.activated && (
              <Button
                btnClass={'empty-button'}
                btnText={
                  <div className='btn-flex'>
                    <Person />
                    <p>Pay for more students</p>
                  </div>
                }
                onClick={() => openModal()}
              />
            )}
          </div>
        </div>
        <div className='student-list__summary-cards'>
          <div className='student-list__summary-cards__card'>
            <div className='student-list__summary-cards__card__icon'>
              <Microwave />
            </div>
            <div className='student-list__summary-cards__card__text'>
              <h3>TOTAL NO OF STUDENTS</h3>
              {statsLoading ? <p>Getting data...</p> : <h1>{totalStudents}</h1>}
            </div>
          </div>

          <div className='student-list__summary-cards__card'>
            <div className='student-list__summary-cards__card__icon'>
              <Profile />
            </div>
            <div className='student-list__summary-cards__card__text'>
              <h3>NO OF STUDENTS PAID FOR</h3>
              {statsLoading ? (
                <p>Getting data...</p>
              ) : (
                <h1>{Number(stockLeft)}</h1>
              )}
            </div>
          </div>

          <div className='student-list__summary-cards__card'>
            <div className='student-list__summary-cards__card__icon'>
              <PersonRemove />
            </div>
            <div className='student-list__summary-cards__card__text'>
              <h3>NO OF STUDENTS WITHOUT SEMAAS NUM </h3>
              {statsLoading ? (
                <p>Getting data...</p>
              ) : (
                <h1>{studentsWithoutIdentity}</h1>
              )}
            </div>
          </div>

          <div className='student-list__summary-cards__card'>
            <div className='student-list__summary-cards__card__icon'>
              <PersonAdd />
            </div>
            <div className='student-list__summary-cards__card__text'>
              <h3>NO OF STUDENTS WITH SEMAAS NUM </h3>
              {statsLoading ? (
                <p>Getting data...</p>
              ) : (
                <h1>{studentsWithIdentity}</h1>
              )}
            </div>
          </div>
        </div>

        <div className='student-list__table'>
          <div className='student-list__table__filter'>
            <div
              className={`student-list__table__filter__tag ${
                activeFilter === 'all' && 'filter-active'
              }`}
              onClick={() => {
                setActiveFilter('all');
                dispatch(getStudents(currentPage));
              }}
            >
              <p>All</p>
              {/* <div className='student-list__table__filter__tag__badge'>100</div> */}
            </div>

            <div
              className={`student-list__table__filter__tag ${
                activeFilter === 'generated' && 'filter-active'
              }`}
              onClick={() => {
                setActiveFilter('generated');
                dispatch(getStudents(currentPage, 'with-identity'));
              }}
            >
              <p>SEMAAS No. Generated</p>
              {/* <div className='student-list__table__filter__tag__badge'>40</div> */}
            </div>

            <div
              className={`student-list__table__filter__tag ${
                activeFilter === 'not generated' && 'filter-active'
              }`}
              onClick={() => {
                setActiveFilter('not generated');
                dispatch(getStudents(currentPage, 'without-identity'));
              }}
            >
              <p>SEMAAS No. Not Generated</p>
              {/* <div className='student-list__table__filter__tag__badge'>50</div> */}
            </div>

            <div
              className={`student-list__table__filter__tag ${
                activeFilter === 'printed' && 'filter-active'
              }`}
              onClick={() => {
                setActiveFilter('printed');
                dispatch(getStudents(currentPage, 'with-identity-card'));
              }}
            >
              <p>ID Card Generated</p>
              {/* <div className='student-list__table__filter__tag__badge'>50</div> */}
            </div>
          </div>
          {selectedStudents?.length > 0 && (
            <div className='bulk-actions'>
              <Button
                onClick={() =>
                  dispatch(generateSemaas({ students: selectedStudents }))
                }
                btnClass={'empty-button'}
                btnText={
                  generateLoading ? 'Please wait...' : 'Assign SEMAAS number'
                }
                disabled={generateLoading}
              />

              <Button
                onClick={() =>
                  dispatch(generateIdCard({ students: selectedStudents }, open))
                }
                btnClass={'empty-button'}
                btnText='Generate ID Card'
              />
            </div>
          )}

          {loading ? (
            <Spinner />
          ) : Object?.values(students?.data || [])?.length > 0 ? (
            <>
              <Table columns={columns} data={Object.values(students?.data)} />
              <Pagination
                currentPage={currentPage}
                totalItems={students?.total / students?.per_page}
                paginate={paginate}
              />
            </>
          ) : activeFilter === 'all' ? (
            <div>{emptyState()}</div>
          ) : (
            <div
              className='empty-students__body'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={empty} alt='empty state' width={200} />
              <p>No data available</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='students-list'>
      <Helmet>
        <title>Generate SEMAAS number</title>
      </Helmet>
      <Header />
      <div className='students-list__body'>
        <BreadCrumbs
          prev={'Home'}
          present={'Generate SEMAAS Num'}
          goToPrev={() => navigate(-1)}
          goToPage={() => navigate('/')}
        />
      </div>
      <div>{view()}</div>

      <SuccessModal
        modalOpen={showModal}
        closeModal={closeModal}
        openModal={openModal}
        openPasswordModal={openPasswordModal}
      />

      <IDCardModal
        modalOpen={idCardPayment}
        closeModal={closeModal}
        openModal={openIdCardPayment}
      />

      <CreateModalPassword
        modalOpen={showPasswordModal}
        closeModal={closePasswordModal}
        openModal={openPasswordModal}
      />

      <div style={{ display: 'none' }}>
        <div ref={ref}>
          <div className='id-card'>
            <p className='id-card__sid'>{singleStudent?.identity?.number}</p>

            <p className='id-card__last_name'>{singleStudent?.last_name}</p>
            <p className='id-card__other_names'>{`${singleStudent?.first_name} ${singleStudent?.last_name}`}</p>

            <p className='id-card__school'>{user?.school?.name}</p>
            <p className='id-card__sex'>{user?.gender?.title}</p>
            <p className='id-card__date'>
              {moment(singleStudent?.identity?.created_at).format('ll')}
            </p>
          </div>

          <div className='id-card-back'>
            <img src={idcardback} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Students;
