import React from 'react';

const DownArrow = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_119_4678)'>
        <path
          d='M7.99999 8.78145L11.3 5.48145L12.2427 6.42411L7.99999 10.6668L3.75732 6.42411L4.69999 5.48145L7.99999 8.78145Z'
          fill='#010C15'
          fillOpacity='0.7'
        />
      </g>
      <defs>
        <clipPath id='clip0_119_4678'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownArrow;
