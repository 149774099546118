import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Header from '../../components/Header/Header';
import empty from '../../assets/empty-students.svg';
import Person from '../../icons/Person';
import Modal from 'react-modal';
import { Cancel } from '../../icons/Cancel';
import logo from '../../assets/logo-new.jpg';
import upload from '../../assets/cloud_upload.svg';
import Dropzone from 'react-dropzone';
import toast from 'react-hot-toast';
import { handleUpload } from '../../services/handleUpload';

import './ato.scss';
import Button from '../../components/Button/Button';
import PersonAdd from '../../icons/PersonAdd';
import Table from '../../components/Table/Table';
import SuccessModal from '../../components/Modals/SuccessModal/SuccessModal';
import CreateModalPassword from '../../components/Modals/CreatePasswordModal/CreateModalPassword';
import { useDispatch, useSelector } from 'react-redux';
import { getAtoRequests } from '../../redux/actions/atoActions';
import Spinner from '../../components/Spinner/Spinner';
import { formatBytes } from '../../utils/utils';

import PrintWhite from '../../icons/PrintWhite';
import ViewAtoModal from '../../components/Modals/ViewATOModal/ViewAtoModal';

const Ato = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [file, setFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(null);
  const [viewAto, setViewAto] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '600px',
    },
  };

  const dispatch = useDispatch();
  const { loading, requests } = useSelector((state) => state.ato);

  const [showModal, setShowModal] = useState(!user?.school?.activated);
  const [showAtoModal, setShowAtoModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setShowAtoModal(false);
    setViewAto(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const openViewAto = () => {
    setViewAto(true);
  };

  useEffect(() => {
    dispatch(getAtoRequests());
  }, []);

  //table headers
  const columns = [
    {
      Header: 'SCHOOL NAME',
      accessor: (d) => `${d.name}`,
      Cell: <p> {user?.school?.name}</p>,
    },

    {
      Header: 'SVC',
      accessor: 'identity',
      Cell: ({ cell: { value } }) => <p> {value ? value : 'Not Available'}</p>,
    },

    {
      Header: 'ATO STATUS',
      accessor: (d) => `${d.status}`,
      Cell: ({ cell: { value } }) => <Badge value={value} />,
    },

    {
      Header: 'REJECTION REASON',
      accessor: (d) => d?.rejection_reason,
      Cell: ({ cell: { value } }) => (
        <p style={{ textAlign: 'center' }}> {value ? value : '-'}</p>
      ),
    },

    {
      Header: 'Actions',
      accessor: (d) => <GenerateButton value={d} />,
      Cell: ({ cell: { value } }) => value,
    },
  ];

  const Badge = ({ value }) => {
    return <div className={value?.toLowerCase()}>{value}</div>;
  };

  const GenerateButton = ({ value }) => {
    return (
      <>
        {(value?.status === 'rejected' && value?.action?.id === 2) ||
        value?.action?.id === 1 ? (
          <div className='generate-flex' onClick={() => openModal()}>
            <PersonAdd />
            <p>Pay for another ATO</p>
          </div>
        ) : value?.status === 'approved' ? (
          <div
            className='generate-flex'
            // onClick={() => window.open(value?.file, '_blank')}
            onClick={() => {
              setViewAto(true);
            }}
          >
            <PersonAdd />
            <p>View ATO</p>
          </div>
        ) : value?.action?.id === 3 ? (
          <div className='generate-flex' onClick={() => setShowAtoModal(true)}>
            <PersonAdd />
            <p>Upload Another ATO</p>
          </div>
        ) : (
          <p>-</p>
        )}
      </>
    );
  };

  const view = () => {
    return (
      <div className='student-list'>
        <div className='student-list__heading'>
          <div className='student-list__heading__left'>
            <h3>Approval To Operate (ATO) Status</h3>
          </div>
          {requests?.approvalRequests?.data?.some(
            (e) => e.status !== 'approved'
          ) && (
            <div className='student-list__heading__right'>
              <Button
                btnClass={'empty-button'}
                btnText={
                  <div className='btn-flex'>
                    <PrintWhite />
                    <p>Upload Another ATO</p>
                  </div>
                }
                onClick={() => setShowAtoModal(true)}
              />
            </div>
          )}
        </div>

        {loading ? (
          <Spinner />
        ) : requests?.approvalRequests?.data?.length > 0 ? (
          <div className='student-list__table'>
            <Table columns={columns} data={requests?.approvalRequests?.data} />
          </div>
        ) : (
          <div className='empty-students'>
            <div className='empty-students__body'>
              <img src={empty} alt='empty state' />
              <h4>You have not uploaded an ATO yet</h4>
              <p>
                You currently do not have any ato uploaded, please upload an ato
              </p>
              <div className='empty-students__body__btn'>
                <Button
                  onClick={() => setShowAtoModal(true)}
                  btnClass={'empty-button'}
                  btnText={'Go to ATO Upload'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const uploadAto = async (item) => {
    try {
      setUploadLoading(true);
      let res = await handleUpload(item);
      toast.success(res?.message);
      setUploadLoading(false);
      dispatch(getAtoRequests());
    } catch (error) {
      setUploadLoading(false);
      console.log(error);
      toast.error('Unable to upload, please try again');
    }
  };
  return (
    <div className='students-list'>
      <Helmet>
        <title>ATO Status</title>
      </Helmet>
      <Header />
      <div className='students-list__body'>
        <BreadCrumbs
          prev={'Home'}
          present={'Check ATO Status'}
          goToPrev={() => navigate(-1)}
          goToPage={() => navigate('/')}
        />
      </div>
      {<div>{view()}</div>}

      <SuccessModal
        ato={true}
        modalOpen={showModal}
        closeModal={closeModal}
        openModal={openModal}
      />

      <Modal
        isOpen={showAtoModal}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className='modal-cancel' onClick={() => closeModal()}>
          <Cancel />
        </div>

        <div className='login-modal'>
          <div className='login-modal__heading'>
            <img src={logo} alt='' />
            <h2>
              Cross River State Education Management and Administration
              Automation System
            </h2>
            <p>Re- upload your Approval to Operate (ATO) document</p>
          </div>

          {uploadLoading ? (
            <Spinner />
          ) : (
            <Dropzone
              onDrop={(acceptedFiles) => {
                uploadAto(acceptedFiles[0]);
                setFile(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className='image-drop'>
                  <div className='image-drop__dash'>
                    <input
                      {...getInputProps()}
                      className='image-drop__input'
                      type='file'
                    />
                    <div {...getRootProps()}>
                      <img src={upload} alt='' />

                      <p>Drag 'n' drop some or</p>
                      <p style={{ color: '#439ADE' }}>Browse your computer</p>
                    </div>
                  </div>

                  {file && (
                    <>
                      <div className='upload-done'>
                        <p>Done !</p>
                      </div>
                      <div className='upload-done__image-name'>
                        <svg
                          width='16'
                          height='20'
                          viewBox='0 0 16 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z'
                            fill='#010C15'
                            fillOpacity='0.7'
                          />
                        </svg>
                        <div className='upload-done__image-name__details'>
                          <p>{file[0]?.path}</p>
                          <p>{formatBytes(file[0]?.size)}</p>
                        </div>
                      </div>
                    </>
                  )}
                </section>
              )}
            </Dropzone>
          )}
        </div>
        <div>
          <Button
            btnClass={'reg-button'}
            btnText={'Continue'}
            onClick={() => {
              closeModal();
              dispatch(getAtoRequests());
              setFile(null);
            }}
          />
        </div>
      </Modal>

      <ViewAtoModal
        closeModal={closeModal}
        modalOpen={viewAto}
        schoolName={user?.school?.name}
      />
    </div>
  );
};

export default Ato;
