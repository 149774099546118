import React from 'react';

const ArrowForward = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_119_4234)'>
        <path
          d='M7.99984 2.6665L7.05984 3.6065L10.7798 7.33317H2.6665V8.6665H10.7798L7.05984 12.3932L7.99984 13.3332L13.3332 7.99984L7.99984 2.6665Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_119_4234'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowForward;
