import React from 'react';

const PrintWhite = () => {
  return (
    <svg
      width='10'
      height='12'
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.66634 4H6.99967V0H2.99967V4H0.333008L4.99967 8.66667L9.66634 4ZM4.33301 5.33333V1.33333H5.66634V5.33333H6.44634L4.99967 6.78L3.55301 5.33333H4.33301ZM0.333008 10H9.66634V11.3333H0.333008V10Z'
        fill='white'
      />
    </svg>
  );
};

export default PrintWhite;
