import ChevronRight from '../../icons/ChevronRight';
import './breadcrumbs.scss';

const BreadCrumbs = ({ goToPrev, goToPage, prev, present }) => {
  return (
    <div className='breadcrumbs'>
      <div onClick={goToPrev} style={{ cursor: 'pointer' }}>
        <ChevronRight />
      </div>

      <div className='breadcrumbs__text'>
        <p className='breadcrumbs__text__prev' onClick={goToPage}>
          {prev}
        </p>
        <p>/</p>
        <p className='breadcrumbs__text__active'>{present}</p>
      </div>
    </div>
  );
};

export default BreadCrumbs;
