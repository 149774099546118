import Header from '../../components/Header/Header';
import Search from '../../icons/Search';
import { Helmet } from 'react-helmet';
import './home.scss';
import Card from '../../components/Card/Card';
import { cardData } from '../../data';
import { useDebounce } from 'use-debounce';
import LoginModal from '../../components/Modals/LoginModal/LoginModal';
import {
  openModal,
  searchForStudents,
  searchSuccess,
} from '../../redux/actions/utilsActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useRef, useState, useEffect } from 'react';
import empty from '../../assets/empty-students.svg';
import Spinner from '../../components/Spinner/Spinner';
import Dot from '../../icons/Dot';
import { toast } from 'react-hot-toast';
import Zoom from 'react-reveal/Zoom';
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef(null);
  const [onSearchText, setSearchText] = useState('');
  const [debouncedText] = useDebounce(onSearchText, 1200);
  const { schools, loading } = useSelector((state) => state.utils);

  let token = localStorage.getItem('token');

  const showToast = (text) => {
    toast(text, {
      icon: '⏱️',
    });
  };

  const action = (path) => {
    if (path === '/register') {
      navigate('/register');
    } else if (token) {
      navigate(path);
    } else {
      dispatch(openModal());
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (!onSearchText) {
      dispatch(
        searchSuccess({
          schools: { data: [] },
        })
      );
    }
  }, [onSearchText]);

  useEffect(() => {
    if (debouncedText) {
      dispatch(searchForStudents(debouncedText));
    }
  }, [debouncedText]);

  let notNeededId = [5, 3, 4];

  return (
    <>
      <div className='home'>
        <Helmet>
          <title>Home</title>
        </Helmet>
        <Header type={'primary'} page='home' />

        <div className='home__hero-section'>
          <h1>
            Cross River State Education Management and Administration Automation
            System
          </h1>

          <p>Search for governement approved public and private schools</p>

          <div className='home__hero-section__input'>
            <input
              type={'text'}
              value={onSearchText}
              onChange={(e) => onSearch(e)}
              placeholder='Search for school name or school verification code (svc)'
            />
            <Search />
          </div>

          {onSearchText.length > 1 && (
            <div className='search-results'>
              <div className='search-results__heading'>
                <p>School Name</p>
                <p>SVC</p>
                <p>Approval Status</p>
              </div>

              {loading ? (
                <div className='home-spinner'>
                  <Spinner />
                </div>
              ) : schools?.data?.length === 0 ? (
                <div
                  className='empty-students__body'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={empty} alt='empty state' width={100} />
                  <p>No data available</p>
                </div>
              ) : (
                schools?.data?.map((el) => (
                  <div className='search-results__body' key={el.id}>
                    <div className='search-results__body__item'>
                      <p className='search-results__body__item__text'>
                        {el?.name}
                      </p>
                      <p className='search-results__body__item__text'>
                        {el?.approval?.code}
                      </p>
                      <p>
                        {el?.approval?.code ? (
                          <div className='search-results__body__item__approved'>
                            <Dot />
                            <p>Approved</p>
                          </div>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
      <section className='home__actions-list'>
        <div className='home__actions-list__title'>
          <h2>
            Perform quick actions on <br /> CR. SEMAAS
          </h2>
        </div>
        <div className='home__actions-list__cards-wrapper' ref={myRef}>
          {cardData.map((el) => (
            <Card
              key={el.id}
              subTitle={el.subTitle}
              title={el.title}
              iconBg={el.iconBg}
              icon={el.icon}
              onClick={() =>
                notNeededId.includes(el.id)
                  ? showToast(`${el.title} feature is coming soon`)
                  : el.title === 'Login'
                  ? dispatch(openModal())
                  : action(el.path)
              }
            />
          ))}
        </div>
      </section>

      <section className='home__speech'>
        <div className='home__speech__title'>
          <h2>
            Welcome to <br /> Cross River State
          </h2>
        </div>

        <div className='home__speech__flex'>
          <div className='home__speech__flex__left'>
            <p>
              "Education is the passport to the future, for tomorrow belongs to
              those who prepare for it today" - Malcolm X <br />
              <br />
              Education in Cross River State, Nigeria is an essential part of
              life and the foundation for a prosperous future. With a wide range
              of educational options, from world-class universities to
              specialized academies, there is something for everyone.
              <br /> <br />
              The state is home to a vibrant educational community, with highly
              qualified teachers and inspiring educational leaders.
              <br />
              Schools in the state offer a variety of programs and
              opportunities, from traditional classroom learning to modern
              technology-focused classes. <br /> <br />
              The state's commitment to education is evident in its ongoing
              investment in educational infrastructure and its dedication to
              providing students with the resources they need to succeed.
              <br /> <br /> With its rich culture and history, Cross River State
              is a great place for students to receive an excellent education.
            </p>
          </div>

          <div className='home__speech__flex__right'>
            {/* <div className='home__speech__flex__right__name'>
              <h4>Sir Ayade Benedict Bengiuoshuye CON</h4>
              <p>Governor of Cross River State</p>
            </div> */}
          </div>
        </div>
      </section>
      <section className='home__cta'>
        <h2>Sign up on CR. SEMAAS</h2>
        <button className='home__cta__btn'>Register</button>
      </section>
      <LoginModal />
    </>
  );
};

export default Home;
