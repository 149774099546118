import axios from 'axios';
import { baseURL } from '../utils/axios';

//upload avatar
export const handleUpload = async (item) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('ATO', item);

  const response = await axios.post(
    `${baseURL}/schools/${user?.school?.id}/approval-requests/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
