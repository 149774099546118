import React from 'react';

const Generate = () => {
  return (
    <svg
      width='38'
      height='28'
      viewBox='0 0 38 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.6667 7.33317C20.6667 3.64984 17.6833 0.666504 14 0.666504C10.3167 0.666504 7.33333 3.64984 7.33333 7.33317C7.33333 11.0165 10.3167 13.9998 14 13.9998C17.6833 13.9998 20.6667 11.0165 20.6667 7.33317ZM17.3333 7.33317C17.3333 9.1665 15.8333 10.6665 14 10.6665C12.1667 10.6665 10.6667 9.1665 10.6667 7.33317C10.6667 5.49984 12.1667 3.99984 14 3.99984C15.8333 3.99984 17.3333 5.49984 17.3333 7.33317ZM0.666666 23.9998V27.3332H27.3333V23.9998C27.3333 19.5665 18.45 17.3332 14 17.3332C9.55 17.3332 0.666666 19.5665 0.666666 23.9998ZM4 23.9998C4.33333 22.8165 9.5 20.6665 14 20.6665C18.4833 20.6665 23.6333 22.7998 24 23.9998H4ZM32.3333 18.9998V13.9998H37.3333V10.6665H32.3333V5.6665H29V10.6665H24V13.9998H29V18.9998H32.3333Z'
        fill='#101110'
      />
    </svg>
  );
};

export default Generate;
