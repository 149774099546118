import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  paymentLoading: false,
  order: {},
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ORDER_START:
      return {
        ...state,
        paymentLoading: true,
        error: null,
      };

    case actionTypes.CREATE_ORDER_FAIL:
      return {
        ...state,
        paymentLoading: false,
        error: action.payload.error,
      };

    case actionTypes.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
        error: null,
        order: action.payload.data,
      };

    case actionTypes.UPDATE_ORDER_START:
      return {
        ...state,
        paymentLoading: true,
        error: null,
      };

    case actionTypes.UPDATE_ORDER_FAIL:
      return {
        ...state,
        paymentLoading: false,
        error: action.payload.error,
      };

    case actionTypes.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
        error: null,
        order: action.payload.data,
      };

    default:
      return state;
  }
};

export default paymentReducer;
