import './card.scss';

const Card = ({ iconBg, title, subTitle, icon, onClick }) => {
  //   function changeBackground() {
  //     let bg = document.getElementById(`icon-bg-${iconBg}`);
  //     bg.style.background = '#fff';
  //   }

  return (
    <div className='action-card' onClick={onClick}>
      <div
        className='action-card__icon'
        style={{ background: iconBg }}
        id={`icon-bg-${iconBg}`}
      >
        {icon}
      </div>
      <div className='action-card__text'>
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </div>
    </div>
  );
};

export default Card;
