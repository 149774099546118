import * as services from '../../services/studentService';
import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';
import * as utilsServices from '../../services/utilsService';

//auth dispatch
export const getStart = () => {
  return {
    type: actionTypes.GET_STUDENTS_START,
  };
};

export const getFail = (error) => {
  return {
    type: actionTypes.GET_STUDENTS_FAIL,
    payload: {
      error,
    },
  };
};

export const getSuccess = (data) => {
  return {
    type: actionTypes.GET_STUDENTS_SUCCESS,
    payload: {
      data,
    },
  };
};

//actions
export const getStudents = (page, filter) => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.getStudents(page, filter);
      dispatch(getSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch students list'
      );
      dispatch(getFail(error.response.data.data.error));
    }
  };
};

export const postStart = () => {
  return {
    type: actionTypes.GENERATE_SEMAAS_START,
  };
};

export const postFail = (error) => {
  return {
    type: actionTypes.GENERATE_SEMAAS_FAIL,
    payload: {
      error,
    },
  };
};

export const postSuccess = (data) => {
  return {
    type: actionTypes.GENERATE_SEMAAS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const generateSemaas = (data) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user?.school?.approval) {
    toast.error('School has not been approved');
    return;
  }
  return async (dispatch) => {
    dispatch(postStart());
    try {
      const response = await services.generateSemaas(data);
      dispatch(postSuccess(response.data));
      toast.success('SEMAAS generated successfuly');
      dispatch(getStudents(1));
      dispatch(getStats());
    } catch (error) {
      dispatch(postFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to generate SEMAAS number'
      );
      dispatch(postFail(error.response.data.data.error));
    }
  };
};

export const printIdStart = () => {
  return {
    type: actionTypes.PRINT_ID_START,
  };
};

export const printIdFail = (error) => {
  return {
    type: actionTypes.PRINT_ID_FAIL,
    payload: {
      error,
    },
  };
};

export const printIdSuccess = (data) => {
  return {
    type: actionTypes.PRINT_ID_SUCCESS,
    payload: {
      data,
    },
  };
};

export const generateIdCard = (data, callback) => {
  return async (dispatch) => {
    dispatch(printIdStart());
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');

      let res = await utilsServices.getStock(user?.school?.id, token, 3);

      if (res.message === 'No record found') {
        callback();
        dispatch(printIdFail(res.message));
      } else {
        const response = await services.printIdCard(data);
        dispatch(printIdSuccess(response.data));
        toast.success('ID card generated successfuly');
        dispatch(getStudents(1));
        dispatch(getStats());
      }
    } catch (error) {
      dispatch(printIdFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to print ID Card'
      );
      dispatch(printIdFail(error.response.data.data.error));
    }
  };
};

//STATS SUMMARY
export const getStatsStart = () => {
  return {
    type: actionTypes.GET_STATS_START,
  };
};

export const getStatsFail = (error) => {
  return {
    type: actionTypes.GET_STATS_FAIL,
    payload: {
      error,
    },
  };
};

export const getStatsSuccess = (data) => {
  return {
    type: actionTypes.GET_STATS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getStats = () => {
  return async (dispatch) => {
    dispatch(getStatsStart());
    try {
      const response = await services.getStats();
      dispatch(getStatsSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch students list'
      );
      dispatch(getStatsFail(error.response.data.data.error));
    }
  };
};
