import { combineReducers } from 'redux';
import atoReducer from './atoReducer';
import authReducer from './authReducer';
import modalReducer from './modalReducer';
import paymentReducer from './paymentReducer';
import studentsReducer from './studentsReducer';
import utilsReducer from './utilsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  payment: paymentReducer,
  students: studentsReducer,
  ato: atoReducer,
  utils: utilsReducer,
});

export default rootReducer;
