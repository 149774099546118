import { useState } from 'react';
import Modal from 'react-modal';
import { Cancel } from '../../../icons/Cancel';
import Button from '../../Button/Button';
import RemitaPayment from 'react-remita';
import Select from 'react-select';
import {
  createOrder,
  updateOrder,
} from '../../../redux/actions/paymentActions';
import { getStats, getStudents } from '../../../redux/actions/studentsActions';
import { useDispatch, useSelector } from 'react-redux';

import './idcardmodal.scss';
import TextInput from '../../Input/TextInput';
import { useNavigate } from 'react-router';
import { numbersOnly } from '../../../utils/utils';

const IDCardModal = ({ modalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { paymentLoading, order } = useSelector((state) => state.payment);
  const navigate = useNavigate();
  const [view, setView] = useState(1);

  const refresh = () => {
    dispatch(getStats());
    dispatch(getStudents());
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '400px',
    },
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  let paymentData = {
    key: process.env.REACT_APP_REMITA_KEY, // enter your key here
    customerId: '',
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    amount: order?.total,
    narration: `payment from school${user?.school?.name}`,
  };

  const pushRoute = () => {
    navigate('/students-list');
    closeModal();
  };

  let data = {
    ...paymentData,
    onSuccess: function (response) {
      dispatch(
        updateOrder(
          user?.school?.id,
          {
            providerReference: response.paymentReference,
          },
          token,
          order?.reference,
          pushRoute(),
          ''
        )
      );
      refresh();
    },
    onError: function (response) {
      // function callback when payment fails
      console.log('callback Error Response', response);
    },
    onClose: function () {
      // function callback when payment modal is closed
    },
  };

  const options = [
    {
      value: '3',
      label: 'Print Identity Card - NGN 500 / Student',
    },
  ];
  const [selectedOptions, setSelectedOptions] = useState();

  const [state, setState] = useState({
    noOfStudents: 0,
    term: '',
  });

  const [errors, setErrors] = useState({
    noOfStudents: '',
    term: '',
  });

  const handleSelect = (data) => {
    setSelectedOptions(data);
  };

  const handleChange = (evt) => {
    const value = evt.target.value;

    if (evt.target.name === 'noOfStudents' && value !== '') {
      if (numbersOnly(value) === false) {
        setErrors({
          ...errors,
          [evt.target.name]: `Field can only contain numbers`,
        });
      } else {
        setErrors({
          ...errors,
          [evt.target.name]: ``,
        });
      }
    }
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const selectValue = (option, name) => {
    setState({ ...state, [name]: option });
  };

  let orderData = {
    provider: 1,
    items: [
      {
        bill: 3,
        term:
          state?.term?.toLowerCase() === 'first term'
            ? 1
            : state?.term?.toLowerCase() === 'second term'
            ? 2
            : 3,
        units: state?.noOfStudents,
      },
    ],
  };

  const moveToNextView = () => {
    setView(2);
  };
  const initCreateOrder = () => {
    let data = {
      provider: orderData.provider,
      items: orderData.items,
    };
    dispatch(createOrder(user?.school?.id, data, token, moveToNextView));
  };

  return (
    <Modal
      isOpen={modalOpen}
      style={customStyles}
      onRequestClose={closeModal}
      contentLabel='Example Modal'
    >
      <div className='modal-cancel' onClick={() => closeModal()}>
        <Cancel />
      </div>

      {view === 1 ? (
        <div className='login-modal'>
          <div className='login-modal__heading'>
            <h2>Please make payment to continue</h2>
            <p>
              You currently don't have enough units left for ID card generation
            </p>
          </div>

          <Select
            options={options}
            value={selectedOptions}
            defaultValue={options[0]}
            onChange={handleSelect}
          />

          <div style={{ marginTop: '15px' }}>
            <TextInput
              label='Number of students'
              fieldClass={'input-field'}
              placeholder='Please enter number of students being paid for'
              name='noOfStudents'
              value={state.noOfStudents}
              handleChange={handleChange}
              type='number'
              errorClass={'error-msg'}
              errorMessage={errors['noOfStudents']}
            />

            <TextInput
              label='Term to be paid for'
              placeholder='Select terms your school is paying for'
              name='term'
              value={state.term}
              onSelectValue={selectValue}
              type='dropdown'
              options={[
                { value: 'first term', label: 'first term' },
                { value: 'second term', label: 'second term' },
                { value: 'third term', label: 'third term' },
              ]}
              errorClass={'error-msg'}
            />
          </div>

          <div className='double-btn-flex'>
            <Button
              btnClass={'secondary-button'}
              onClick={() => closeModal()}
              btnText={<p style={{ color: '#4b8e64' }}>Cancel</p>}
            />

            <Button
              btnClass={'primary-button'}
              onClick={() => initCreateOrder()}
              btnText={paymentLoading ? 'Please wait...' : 'Confirm Payment'}
              disabled={paymentLoading || errors?.noOfStudents}
            />
          </div>
        </div>
      ) : (
        <div className='login-modal'>
          <div className='login-modal__heading'>
            <h2>Summary</h2>
            <p>{order?.reference}</p>
            <h3>Total - {`NGN ${Number(order?.total)?.toLocaleString()}`}</h3>
          </div>
          <div className='double-btn-flex'>
            <Button
              btnClass={'secondary-button'}
              onClick={() => setView(1)}
              btnText={<p style={{ color: '#4b8e64' }}>Back</p>}
            />
            <RemitaPayment
              remitaData={data}
              className='primary-button' // class to style the button
              text={`Pay N${Number(order?.total)?.toLocaleString()}`} //text to show on button
              // add a 'live' prop to use the live urls/keys
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default IDCardModal;
