import React from 'react';

const Edit = () => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_119_4680)'>
        <path
          d='M10.4853 6.95739L9.54267 6.01472L3.33333 12.2241V13.1667H4.276L10.4853 6.95739ZM11.428 6.01472L12.3707 5.07206L11.428 4.12939L10.4853 5.07206L11.428 6.01472ZM4.828 14.5001H2V11.6714L10.9567 2.71472C11.0817 2.58974 11.2512 2.51953 11.428 2.51953C11.6048 2.51953 11.7743 2.58974 11.8993 2.71472L13.7853 4.60072C13.9103 4.72574 13.9805 4.89528 13.9805 5.07206C13.9805 5.24883 13.9103 5.41837 13.7853 5.54339L4.82867 14.5001H4.828Z'
          fill='#020E24'
        />
      </g>
      <defs>
        <clipPath id='clip0_119_4680'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Edit;
