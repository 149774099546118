import Login from './icons/Login';
import Register from './icons/Register';
import Download from './icons/Download';
import Identity from './icons/Identity';
import Generate from './icons/Generate';
import Eye from './icons/Eye';

export const cardData = [
  {
    id: 1,
    title: 'Register',
    subTitle: 'Signup your school on CR. SEMAAS here',
    icon: <Register />,
    iconBg: '#E9FFF1',
    path: '/register',
  },
  {
    id: 2,
    title: 'Login',
    subTitle: 'Login to access your CR. SEMAAS dashboard here',
    icon: <Login />,
    iconBg: '#E9EAFF',
    path: 'https://crsemaas.edves.net/login/',
  },

  {
    id: 5,
    title: 'Print ATO',
    subTitle: 'Print your school’s Approval to Operate document here',
    icon: <Download />,
    iconBg: '#F0FFD9',
    path: '/ato',
  },
  {
    id: 6,
    title: 'Generate SEMAAS Num',
    subTitle: `Generate your student’s SEMAAS number here`,
    icon: <Generate />,
    iconBg: '#FFE6F0',
    path: '/students-list',
  },

  {
    id: 3,
    title: 'Print ID Card',
    // subTitle: 'Print your student CR. SEMAAS ID card  here',
    subTitle: 'This feature is coming soon',
    icon: <Identity />,
    iconBg: '#FFF1E9',
    path: '#',
  },
  {
    id: 4,
    title: 'Check ATO Status',
    subTitle: `Print your school's Approval to Operate ID card here`,
    icon: <Eye />,
    iconBg: '#ECFFE9',
    path: '/ato',
  },
];

export const crossRiverLGA = [
  {
    id: 1,
    value: 'abi',
    label: 'Abi',
  },
  {
    id: 2,
    value: 'akpabuyo',
    label: 'Akpabuyo',
  },
  {
    id: 3,
    value: 'bakassi',
    label: 'Bakassi',
  },
  {
    id: 4,
    value: 'bakwara',
    label: 'Bakwara',
  },
  {
    id: 5,
    value: 'biase',
    label: 'Biase',
  },
  {
    id: 6,
    value: '1',
    label: 'Boki calabar municipal',
  },
  {
    id: 7,
    value: '2',
    label: 'Boki calabar municipal',
  },
  {
    id: 8,
    value: '3',
    label: 'Boki calabar municipal',
  },
  {
    id: 9,
    value: '4',
    label: 'Boki calabar municipal',
  },
  {
    id: 10,
    value: '5',
    label: 'Boki calabar municipal',
  },
];

const schoolInformation = JSON.parse(localStorage.getItem('information'));
const contactDetails = JSON.parse(localStorage.getItem('contactDetails'));

export const summary = [
  {
    id: 1,
    title: 'School Information',
    path: 'information',
    content: [
      { id: 1, key: 'School Name', val: schoolInformation?.schoolName },
      { id: 2, key: 'School Type', val: schoolInformation?.schoolType },
      { id: 3, key: 'School Level', val: schoolInformation?.schoolLevel },
      {
        id: 4,
        key: 'School Local Government Area',
        val: schoolInformation?.schoolLGA,
      },
      {
        id: 5,
        key: 'School Local Zone',
        val: schoolInformation?.schoolZone,
      },
      {
        id: 6,
        key: 'School Address',
        val: schoolInformation?.schoolAddress,
      },
      {
        id: 7,
        key: 'Name of Pricipal',
        val: schoolInformation?.principalName,
      },
      {
        id: 8,
        key: 'Name of Headmaster',
        val: schoolInformation?.headmasterName,
      },
      {
        id: 9,
        key: 'Name of Proprietor',
        val: schoolInformation?.proprietorName,
      },

      {
        id: 11,
        key: 'Number of Students paid for',
        val: schoolInformation?.noOfStudents,
      },
    ],
  },

  // {
  //   id: 2,
  //   title: 'Payment Summary',
  //   path: 'information',
  //   content: [
  //     { id: 1, key: 'Price per student', val: 'NGN 200' },
  //     {
  //       id: 2,
  //       key: `Total price for ${schoolInformation?.noOfStudents}`,
  //       val: `NGN ${(200 * schoolInformation?.noOfStudents)?.toLocaleString()}`,
  //     },
  //     {
  //       id: 3,
  //       key: 'Total Amount Due',
  //       val: `NGN ${(200 * schoolInformation?.noOfStudents)?.toLocaleString()}`,
  //     },
  //   ],
  // },

  {
    id: 3,
    title: 'Contact and Login details',
    path: 'contact',
    content: [
      { id: 10, key: 'Full Name', val: contactDetails?.fullname },
      { id: 1, key: 'Email Address', val: contactDetails?.email },
      {
        id: 2,
        key: `Mobile Number`,
        val: contactDetails?.phoneNumber,
      },
      {
        id: 3,
        key: 'Username',
        val: contactDetails?.username,
      },
      { id: 11, key: 'Gender', val: contactDetails?.gender },
      {
        id: 4,
        key: 'Password',
        val: contactDetails?.password,
      },
      {
        id: 5,
        key: 'Confirm password',
        val: contactDetails?.confirmPassword,
      },
      {
        id: 6,
        key: 'School Address',
        val: schoolInformation?.schoolAddress,
      },
      {
        id: 7,
        key: 'Name of Pricipal',
        val: schoolInformation?.principalName,
      },
      {
        id: 8,
        key: 'Name of Headmaster',
        val: schoolInformation?.headmasterName,
      },
      {
        id: 9,
        key: 'Name of Proprietor',
        val: schoolInformation?.proprietorName,
      },
    ],
  },
];
