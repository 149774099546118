import './payment.scss';
import { useState, useEffect } from 'react';
import { capitalize, formatBytes } from '../../utils/utils';
import logo from '../../assets/logo-new.jpg';
import TextInput from '../../components/Input/TextInput';
import Yes from '../../icons/Yes';
import No from '../../icons/No';
import RadioChecked from '../../icons/RadioChecked';
import RadioUnchecked from '../../icons/RadioUnchecked';
import Dropzone from 'react-dropzone';
import upload from '../../assets/cloud_upload.svg';
import { handleUpload } from '../../services/handleUpload';
import { toast } from 'react-hot-toast';
import Spinner from '../../components/Spinner/Spinner';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router';
import { createOrder } from '../../redux/actions/paymentActions';
import { useDispatch, useSelector } from 'react-redux';
import PaymentModal from '../../components/Modals/PaymentModal/PaymentModal';
import { checkATO } from '../../services/atoService';
import { numbersOnly } from '../../utils/utils';

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const { paymentLoading, order } = useSelector((state) => state.payment);

  const token = localStorage.getItem('token');
  const [state, setState] = useState({
    terms: '',
    noOfStudents: '0',
  });

  const [errors, setErrors] = useState({
    terms: '',
    noOfStudents: '',
  });

  const [hasAto, setHasAto] = useState('yes');
  const [atoPrice, setAtoPrice] = useState(0);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(null);

  const [showModal, setShowModal] = useState(false);

  //   const checkAtoStatus = async () => {
  //     try {
  //       let res = await checkATO();
  //       if (res.message?.toLowerCase() === 'school not approved!') {
  //         return;
  //       } else {
  //         toast.success('School has been approved!');
  //         navigate('/students-list');
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   useEffect(() => {
  //     checkAtoStatus();
  //   }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const selectValue = (option, name) => {
    setState({ ...state, [name]: option });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    if (evt.target.name === 'noOfStudents' && value !== '') {
      if (numbersOnly(value) === false) {
        setErrors({
          ...errors,
          [evt.target.name]: `Field can only contain numbers`,
        });
        return;
      } else {
        setErrors({
          ...errors,
          [evt.target.name]: '',
        });
      }

      if (value.includes('-')) {
        setErrors({
          ...errors,
          [evt.target.name]: `Number cannot be negative`,
        });
        return;
      } else {
        setErrors({
          ...errors,
          [evt.target.name]: '',
        });
      }
    }

    setState({
      ...state,
      [evt.target.name]: value,
    });

    // if (value < 50) {
    //   setErrors({
    //     ...errors,
    //     [evt.target.name]: `Minimum number of students is 50`,
    //   });
    // } else {
    //   setErrors({
    //     ...errors,
    //     [evt.target.name]: ``,
    //   });
    // }
  };

  const handleBlur = (evt) => {
    const value = evt.target.value;

    if (!value) {
      setErrors({
        ...errors,
        [evt.target.name]: `Field cannot be empty`,
      });
      // } else if (value < 50) {
      //   setErrors({
      //     ...errors,
      //     [evt.target.name]: `Minimum number of students is 50`,
      //   });
      // }
    } else {
      setErrors({
        ...errors,
        [evt.target.name]: ``,
      });
    }
  };

  const uploadAto = async (item) => {
    try {
      setLoading(true);
      let res = await handleUpload(item);
      toast.success(res?.message);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error('Session expired,please log in again');
        window.location.replace('/');
        localStorage.clear();
        return;
      }
      setLoading(false);
      toast.error('Unable to upload, please try again');
    }
  };

  let orderData = {
    provider: 2,
    items: [
      hasAto === 'no' && {
        bill: 1,
        units: 1,
      },
      {
        bill: 2,
        term:
          state.terms?.toLocaleLowerCase() === 'first term'
            ? 1
            : state.terms?.toLocaleLowerCase() === 'second term'
            ? 2
            : 3,
        units: state.noOfStudents,
      },
    ],
  };

  const initCreateOrder = () => {
    let data = {
      provider: orderData.provider,
      items: orderData.items?.filter((el) => el !== false),
    };
    dispatch(createOrder(user?.school?.id, data, token, openModal));
  };
  return (
    <div className='payment-wrapper'>
      <div className='payment-wrapper__left'>
        <div className='payment-wrapper__left__heading'>
          <img src={logo} alt='' />
          <h2>{capitalize(user?.school?.name)}</h2>
          <p>
            Almost done! Select the number of terms and students you want to
            register for
          </p>
        </div>

        <div className='payment-wrapper__left__form'>
          <TextInput
            label='Terms Paid For'
            placeholder='Select terms your school is paying for'
            name='terms'
            value={state.terms}
            onSelectValue={selectValue}
            type='dropdown'
            options={[
              { value: 'first term', label: 'first term' },
              { value: 'second term', label: 'second term' },
              { value: 'third term', label: 'third term' },
            ]}
            errorClass={'error-msg'}
          />

          <TextInput
            label='Number of students paid for'
            fieldClass={errors['fullname'] ? 'error-field' : 'input-field'}
            placeholder='Enter number of students your school is paying for'
            name='noOfStudents'
            value={state.noOfStudents}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type='text'
            errorMessage={errors['noOfStudents']}
            errorClass={'error-msg'}
          />

          <div className='payment-wrapper__left__form__ato'>
            {/* <label>Do you have an existing ATO?</label> */}
            {/* <div className='payment-wrapper__left__form__ato__flex'>
              <button
                className={
                  hasAto === 'yes'
                    ? `payment-wrapper__left__form__ato__flex__btn-selected`
                    : `payment-wrapper__left__form__ato__flex__btn`
                }
                onClick={() => {
                  setHasAto('yes');
                  setAtoPrice(0);
                }}
              >
                {hasAto === 'yes' ? <Yes /> : <No />}
                <p>Yes</p>
                {hasAto === 'yes' ? <RadioChecked /> : <RadioUnchecked />}
              </button>
              <button
                className={
                  hasAto === 'no'
                    ? `payment-wrapper__left__form__ato__flex__btn-selected`
                    : `payment-wrapper__left__form__ato__flex__btn`
                }
                onClick={() => {
                  setHasAto('no');
                  setAtoPrice(200000);
                }}
              >
                {hasAto === 'no' ? <Yes /> : <No />}
                <p>No</p>
                {hasAto === 'no' ? <RadioChecked /> : <RadioUnchecked />}
              </button>
            </div> */}
            {/* {hasAto === 'no' && (
              <div className='outline'>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M7.3335 10H8.66683V11.3333H7.3335V10ZM7.3335 4.66666H8.66683V8.66666H7.3335V4.66666ZM7.9935 1.33333C4.3135 1.33333 1.3335 4.31999 1.3335 8C1.3335 11.68 4.3135 14.6667 7.9935 14.6667C11.6802 14.6667 14.6668 11.68 14.6668 8C14.6668 4.31999 11.6802 1.33333 7.9935 1.33333ZM8.00016 13.3333C5.0535 13.3333 2.66683 10.9467 2.66683 8C2.66683 5.05333 5.0535 2.66666 8.00016 2.66666C10.9468 2.66666 13.3335 5.05333 13.3335 8C13.3335 10.9467 10.9468 13.3333 8.00016 13.3333Z'
                    fill='#CE7C21'
                  />
                </svg>
                <p>You will be charged NGN 200,000 to obtain a new ATO</p>
              </div>
            )} */}
            {hasAto === 'yes' && (
              <div className='ato-upload'>
                <label>Upload Approval To Operate (ATO) document</label>
                {loading ? (
                  <Spinner />
                ) : (
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      uploadAto(acceptedFiles[0]);
                      setFile(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className='image-drop'>
                        <div className='image-drop__dash'>
                          <input
                            {...getInputProps()}
                            className='image-drop__input'
                            type='file'
                          />
                          <div {...getRootProps()}>
                            <img src={upload} alt='' />

                            <p>Drag 'n' drop some or</p>
                            <p style={{ color: '#439ADE' }}>
                              Browse your computer
                            </p>
                          </div>
                        </div>

                        {file && (
                          <>
                            <div className='upload-done'>
                              <p>Done !</p>
                            </div>
                            <div className='upload-done__image-name'>
                              <svg
                                width='16'
                                height='20'
                                viewBox='0 0 16 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z'
                                  fill='#010C15'
                                  fillOpacity='0.7'
                                />
                              </svg>
                              <div className='upload-done__image-name__details'>
                                <p>{file[0]?.path}</p>
                                <p>{formatBytes(file[0].size)}</p>
                              </div>
                            </div>
                          </>
                        )}
                      </section>
                    )}
                  </Dropzone>
                )}
              </div>
            )}
          </div>
          {hasAto === 'yes' && !file ? (
            <Button
              disabled
              btnClass={'reg-button'}
              btnText={paymentLoading ? 'Please wait...' : 'Continue'}
            />
          ) : (
            <Button
              disabled={
                paymentLoading ||
                state.terms === '' ||
                state.noOfStudents === '0' ||
                errors.noOfStudents ||
                hasAto === ''
              }
              btnClass={'reg-button'}
              btnText={paymentLoading ? 'Please wait...' : 'Continue'}
              onClick={() => {
                initCreateOrder();
              }}
            />
          )}
        </div>
      </div>
      <div className='payment-wrapper__right'>
        <h2>Payment summary</h2>
        <div className='payment-wrapper__right__summary'>
          <div className='payment-wrapper__right__summary__item'>
            <p className='payment-wrapper__right__summary__item__left'>
              Price Per student
            </p>
            <p className='payment-wrapper__right__summary__item__right'>
              NGN 500
            </p>
          </div>

          <div className='payment-wrapper__right__summary__item'>
            <p className='payment-wrapper__right__summary__item__left'>
              Number of students
            </p>
            <p className='payment-wrapper__right__summary__item__right'>
              {state.noOfStudents}
            </p>
          </div>

          <div className='payment-wrapper__right__summary__item'>
            <p className='payment-wrapper__right__summary__item__left'>
              Total Price for students
            </p>
            <p className='payment-wrapper__right__summary__item__right'>
              NGN {(Number(state.noOfStudents) * 500).toLocaleString()}
            </p>
          </div>

          <div className='payment-wrapper__right__summary__item'>
            <p className='payment-wrapper__right__summary__item__left'>
              ATO Document
            </p>
            <p className='payment-wrapper__right__summary__item__right'>
              NGN {atoPrice?.toLocaleString()}
            </p>
          </div>
        </div>

        <div className='payment-wrapper__right__summary__item'>
          <p
            className='payment-wrapper__right__summary__item__left'
            style={{ color: '#010C15' }}
          >
            Total
          </p>
          <p
            className='payment-wrapper__right__summary__item__left'
            style={{ color: '#010C15' }}
          >
            NGN {(state.noOfStudents * 500 + atoPrice)?.toLocaleString()}
          </p>
        </div>
      </div>

      <PaymentModal
        modalOpen={showModal}
        closeModal={closeModal}
        openModal={openModal}
      />
    </div>
  );
};

export default Payment;
