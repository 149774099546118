import React from 'react';

const Person = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_115_4499)'>
        <path
          d='M8.66699 5.33317C8.66699 3.85984 7.47366 2.6665 6.00033 2.6665C4.52699 2.6665 3.33366 3.85984 3.33366 5.33317C3.33366 6.8065 4.52699 7.99984 6.00033 7.99984C7.47366 7.99984 8.66699 6.8065 8.66699 5.33317ZM7.33366 5.33317C7.33366 6.0665 6.73366 6.6665 6.00033 6.6665C5.26699 6.6665 4.66699 6.0665 4.66699 5.33317C4.66699 4.59984 5.26699 3.99984 6.00033 3.99984C6.73366 3.99984 7.33366 4.59984 7.33366 5.33317ZM0.666992 11.9998V13.3332H11.3337V11.9998C11.3337 10.2265 7.78032 9.33317 6.00033 9.33317C4.22033 9.33317 0.666992 10.2265 0.666992 11.9998ZM2.00033 11.9998C2.13366 11.5265 4.20033 10.6665 6.00033 10.6665C7.79366 10.6665 9.85366 11.5198 10.0003 11.9998H2.00033ZM13.3337 9.99984V7.99984H15.3337V6.6665H13.3337V4.6665H12.0003V6.6665H10.0003V7.99984H12.0003V9.99984H13.3337Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_115_4499'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Person;
