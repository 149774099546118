import React from 'react';

const Microwave = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_249_5040)'>
        <path
          d='M13.333 2.6665H2.66634C1.93301 2.6665 1.33301 3.2665 1.33301 3.99984V11.9998C1.33301 12.7332 1.93301 13.3332 2.66634 13.3332H13.333C14.0663 13.3332 14.6663 12.7332 14.6663 11.9998V3.99984C14.6663 3.2665 14.0663 2.6665 13.333 2.6665ZM2.66634 3.99984H9.33301V11.9998H2.66634V3.99984ZM13.333 11.9998H10.6663V3.99984H13.333V11.9998ZM12.6663 5.99984H11.333V4.6665H12.6663V5.99984ZM11.9997 8.6665C11.633 8.6665 11.333 8.3665 11.333 7.99984C11.333 7.63317 11.633 7.33317 11.9997 7.33317C12.3663 7.33317 12.6663 7.63317 12.6663 7.99984C12.6663 8.3665 12.3663 8.6665 11.9997 8.6665ZM11.9997 11.3332C11.633 11.3332 11.333 11.0332 11.333 10.6665C11.333 10.2998 11.633 9.99984 11.9997 9.99984C12.3663 9.99984 12.6663 10.2998 12.6663 10.6665C12.6663 11.0332 12.3663 11.3332 11.9997 11.3332ZM6.83301 10.6665C6.30634 10.6665 5.91967 10.4132 5.63967 10.2265C5.41967 10.0798 5.29301 9.99984 5.16634 9.99984C4.91967 9.99984 4.63301 10.2732 4.53301 10.4065L3.58634 9.45984C3.81967 9.19317 4.39301 8.6665 5.16634 8.6665C5.69967 8.6665 6.09301 8.9265 6.37301 9.11317C6.57967 9.25317 6.71301 9.33317 6.83301 9.33317C7.07967 9.33317 7.36634 9.05984 7.46634 8.9265L8.41301 9.87317C8.17301 10.1398 7.60634 10.6665 6.83301 10.6665ZM6.83301 7.33317C6.30634 7.33317 5.91967 7.07984 5.63967 6.89317C5.41967 6.7465 5.29301 6.6665 5.16634 6.6665C4.91967 6.6665 4.63301 6.93984 4.53301 7.07317L3.57967 6.1265C3.81967 5.85984 4.39301 5.33317 5.16634 5.33317C5.69967 5.33317 6.09301 5.59317 6.37301 5.77984C6.57967 5.91984 6.71301 5.99984 6.83301 5.99984C7.07967 5.99984 7.36634 5.7265 7.46634 5.59317L8.41301 6.53984C8.17301 6.8065 7.60634 7.33317 6.83301 7.33317Z'
          fill='#4B8E64'
        />
      </g>
      <defs>
        <clipPath id='clip0_249_5040'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Microwave;
