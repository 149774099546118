import Accordion from '../../components/Accordion/Accordion';
import Button from '../../components/Button/Button';
import ArrowBackward from '../../icons/ArrowBackward';
import { summary } from '../../data';
import { useDispatch, useSelector } from 'react-redux';
import { signUpSchool } from '../../redux/actions/authActions';
import LoginModal from '../../components/Modals/LoginModal/LoginModal';
import { openModal } from '../../redux/actions/utilsActions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { handleUpload } from '../../services/handleUpload';

const Summary = ({ moveToNextStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const schoolInformation = JSON.parse(localStorage.getItem('information'));
  const contactDetails = JSON.parse(localStorage.getItem('contactDetails'));
  const lga = localStorage.getItem('lga');

  const showLoginModal = () => {
    dispatch(openModal());
  };

  useEffect(() => {
    if (!schoolInformation) {
      navigate('/register');
    }
  }, []);

  const submit = () => {
    let fullname = contactDetails?.fullname;
    let firstName = fullname?.split(' ')[0];
    let lastName = fullname?.split(' ')[1];
    let data = {
      name: schoolInformation?.schoolName,
      schoolType:
        schoolInformation?.schoolType?.toLowerCase() === 'private school'
          ? 1
          : 2,
      schoolLevel:
        schoolInformation?.schoolLevel?.toLowerCase() === 'nursery school'
          ? 1
          : schoolInformation?.schoolLevel?.toLowerCase() === 'primary school'
          ? 2
          : 3,
      state: 9,
      localGovernmentArea: Number(lga),
      zone: Number(schoolInformation?.schoolZone?.replace('Zone ', '')),
      address: schoolInformation?.schoolAddress,
      principalName: schoolInformation?.principalName,
      headmasterName: schoolInformation?.headmasterName,
      proprietorName: schoolInformation?.proprietorName,
      firstName: firstName,
      lastName: lastName,
      gender: contactDetails?.gender?.toLowerCase() === 'male' ? 2 : 1,
      username: contactDetails?.username,
      email: contactDetails?.email,
      phoneNumber: Number(contactDetails?.phoneNumber),
      password: contactDetails?.password,
      passwordConfirmation: contactDetails?.confirmPassword,
    };

    dispatch(signUpSchool(data, showLoginModal));
  };

  return (
    <div>
      {summary.map((el) => (
        <div key={el.id} style={{ marginBottom: '32px' }}>
          <Accordion
            width={'80%'}
            title={el.title}
            content={el.content}
            moveToNextStep={moveToNextStep}
            path={el.path}
            state={el.path}
          ></Accordion>
        </div>
      ))}
      <div className='double-btn-flex'>
        <Button
          onClick={() => {
            moveToNextStep('contact');
          }}
          btnClass={'secondary-button'}
          btnText={
            <div className='btn-flex'>
              <ArrowBackward />
              <p>Go Back</p>
            </div>
          }
        />
        <Button
          btnClass={'primary-button'}
          onClick={() => submit()}
          btnText={loading ? 'Please wait...' : 'Create School'}
          disabled={loading}
        />
        {/* 
<RemitaPayment
              remitaData={data}
              className='remita-btn' // class to style the button
              text='Pay with Remita' //text to show on button
              // add a 'live' prop to use the live urls/keys
            /> */}
      </div>
      <LoginModal ato />
    </div>
  );
};

export default Summary;
