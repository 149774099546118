import './button.scss';

const Button = ({ btnText, onClick, btnClass, disabled }) => {
  return (
    <button onClick={onClick} className={btnClass} disabled={disabled}>
      {btnText}
    </button>
  );
};

export default Button;
