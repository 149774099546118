import './payment.scss';
import Modal from 'react-modal';
import { Cancel } from '../../../icons/Cancel';
import { useState, useEffect } from 'react';
import { useFlutterwave } from 'flutterwave-react-v3';
import Button from '../../Button/Button';
import RemitaPayment from 'react-remita';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrder } from '../../../redux/actions/paymentActions';
import image from '../../../assets/success.svg';
import { useNavigate } from 'react-router';

const PaymentModal = ({ modalOpen, closeModal }) => {
  const { paymentLoading, order } = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [reference, setReference] = useState('');

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '500px',
    },
  };

  let paymentData = {
    key: process.env.REACT_APP_REMITA_KEY, // enter your key here
    customerId: '',
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    amount: order?.total,
    narration: `payment from school${user?.school?.name}`,
  };

  const showSuccessModal = () => {
    closeModal();
    setSuccess(true);
  };

  let data = {
    ...paymentData,
    onSuccess: function (response) {
      dispatch(
        updateOrder(
          user?.school?.id,
          {
            providerReference: response.paymentReference,
          },
          token,
          order?.reference,
          showSuccessModal()
        )
      );
    },
    onError: function (response) {
      // function callback when payment fails
      console.log('callback Error Response', response);
    },
    onClose: function () {
      // function callback when payment modal is closed
    },
  };

  const config = {
    public_key: 'FLWPUBK-f5dd1e6bfba2fbc5d7287d3ef2c0785d-X',
    tx_ref: reference,
    amount: order?.total,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd,banktransfer',
    customer: {
      email: user?.email || '',
      phone_number: '',
      name: `${user?.first_name} ${user?.last_name}` || '',
    },
    subaccounts: [
      {
        id: 'RS_6110232C4BFFD15FA0E8A593D3C3B6CB',
        transaction_charge_type: 'percentage',
        transaction_charge: 0.2,
      },
    ],
    customizations: {
      title: 'Education Management system by edves',
      description: 'Payment',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  useEffect(() => {
    setReference(`SEMS-EDV${Date.now()}${Math.floor(Math.random() * 10 + 1)}`);
  }, []);

  const handleFlutterPayment = useFlutterwave(config);

  const pay = async () => {
    // closeModal();
    handleFlutterPayment({
      callback: async (response) => {
        console.log(response);
        dispatch(
          updateOrder(
            user?.school?.id,
            {
              providerReference: response.transaction_id,
            },
            token,
            order?.reference,
            showSuccessModal()
          )
        );
        showSuccessModal();
      },

      onClose: () => {
        closeModal();
      },
    });
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    navigate('/students-list');
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className='login-modal'>
          <div className='login-modal__heading'>
            <h2>Payment confirmation</h2>
            <p>{order?.reference}</p>
            <h3>Total - {`NGN ${Number(order?.total)?.toLocaleString()}`}</h3>
          </div>
          <div className='double-btn-flex'>
            <Button
              btnClass={'secondary-button'}
              onClick={() => closeModal()}
              btnText={<p style={{ color: '#4b8e64' }}>Back</p>}
            />

            <Button
              btnClass='primary-button'
              onClick={() => pay()}
              btnText={`Pay N${(Number(order?.total) + 50)?.toLocaleString()}`}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={success}
        style={customStyles}
        onRequestClose={closeSuccessModal}
      >
        <div className='payment-success'>
          <div
            className='payment-success__close'
            onClick={() => {
              navigate('/students-list');
            }}
          >
            <Cancel />
          </div>
          <img src={image} alt='' />
          <div className='payment-success__content'>
            <h2>Payment Confirmed!</h2>
            <p>
              We have received your payment, kindly check your dashboard for
              status on your ATO request
            </p>

            <Button
              btnClass={'primary-button w-100'}
              onClick={() => {
                navigate('/students-list');
              }}
              btnText={'Go to Dashboard'}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentModal;
