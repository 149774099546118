import { useState } from 'react';
import './createmodalpassword.scss';
import Modal from 'react-modal';
import { Cancel } from '../../../icons/Cancel';
import TextInput from '../../Input/TextInput';
import Button from '../../Button/Button';
import { createArm } from '../../../services/utilsService';
import toast from 'react-hot-toast';

const CreateModalPassword = ({ modalOpen, closeModal }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '400px',
    },
  };

  const [state, setState] = useState({
    username: user?.username,
    password: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (evt) => {
    const value = evt.target.value;
    if (value.length < 8) {
      setError('Password cannot be less than 8 characters');
    } else {
      setError('');
    }
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createArm(user?.school?.id, state, token);
      toast.success('Created successfully');
      window.open('https://crsemaas.edves.net', '_blank');
      setLoading(false);
      closeModal();
      const newObj = { ...user?.school, ...{ arm: true } };
      let objToBeSaved = Object.assign(user?.school, newObj);
      localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Please ensure you use your CRSEMAAS password');
    }
  };

  return (
    <Modal isOpen={modalOpen} style={customStyles} onRequestClose={closeModal}>
      <div className='modal-cancel' onClick={() => closeModal()}>
        <Cancel />
      </div>

      <div className='login-modal'>
        <div className='login-modal__heading'>
          <h2>Setup Edves Basic Account</h2>
          <p>Please input your password </p>
        </div>
      </div>
      <div>
        <TextInput
          label='Password*'
          placeholder='Please input your CRSEMAAS Password'
          name='password'
          value={state.password}
          handleChange={handleChange}
          type='password'
          errorMessage={error}
          errorClass={'error-msg'}
        />

        <Button
          btnClass={'reg-button'}
          btnText={loading ? 'Please wait...' : 'Create Edves Basic Account'}
          onClick={() => {
            submit();
          }}
          disabled={state.password === '' || error || loading}
        />
      </div>
    </Modal>
  );
};

export default CreateModalPassword;
