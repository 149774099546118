import axios from '../utils/axios';

let user = JSON.parse(localStorage.getItem('user'));

export const getAtoRequests = async () => {
  const response = await axios.get(
    `/schools/${user?.school.id}/approval-requests`
  );
  return response.data;
};

export const checkATO = async () => {
  const response = await axios.get(`/schools/${user?.school.id}/approval`);
  return response.data;
};
