import { useState } from 'react';
import DownArrow from '../../icons/DownArrow';
import Edit from '../../icons/Edit';

import './accordion.scss';

const Accordion = ({ title, width, content, moveToNextStep, state }) => {
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const edit = (state) => {
    moveToNextStep(state);
  };
  return (
    <div className='accordion' style={{ width: width }}>
      <div className='accordion__header' onClick={() => setShow(!show)}>
        <p>{title}</p>
        <div className='accordion__header__actions'>
          <div
            className='accordion__header__actions__edit'
            onClick={() => edit(state)}
          >
            <Edit />
            <p>Edit</p>
          </div>
          <DownArrow />
        </div>
      </div>

      {content?.map((el) => (
        <div
          className={show ? 'accordion__content-show' : 'accordion__content'}
          key={el.id}
        >
          <div className='accordion__content__inner'>
            <p className='accordion__content__inner__key'>{el.key}:</p>
            {el.key?.toLowerCase()?.includes('password') ? (
              <p className='accordion__content__inner__val'>
                {showPassword ? el.val : '*******'}
              </p>
            ) : (
              <p className='accordion__content__inner__val'>{el.val}</p>
            )}
            {el.key?.toLowerCase()?.includes('password') && (
              <p
                style={{
                  fontWeight: '400',
                  textDecoration: 'underline',
                  color: 'rgba(1, 12, 21, 0.7)',
                  cursor: 'pointer',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
