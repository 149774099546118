import React from 'react';
import './pagination.scss';

const Pagination = ({ totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  return (
    <div className='pagination'>
      <div className='pagination__right'>
        <div className='arrow-icons'>
          <button
            onClick={(e) => {
              e.preventDefault();
              prevPage();
            }}
            disabled={currentPage - 1 === 0}
          >
            {currentPage - 1 === 0 ? (
              <svg
                width='9'
                height='15'
                viewBox='0 0 9 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.5 13.5L1.25 7.25L7.5 1'
                  stroke='#707885'
                  strokeWidth='1.2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ) : (
              <svg
                width='9'
                height='15'
                viewBox='0 0 9 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.5 13.5L1.25 7.25L7.5 1'
                  stroke='#4C8D63'
                  strokeWidth='1.2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            )}
          </button>
          {pageNumbers.length > 0 &&
            pageNumbers.map((item) => {
              return (
                <div key={item}>
                  <button
                    onClick={() => paginate(item)}
                    className={`${
                      currentPage === item ? 'active-pagination' : ''
                    }  pagination-btn`}
                  >
                    {item}
                  </button>
                </div>
              );
            })}

          <button
            onClick={(e) => {
              nextPage(e.preventDefault());
            }}
            disabled={currentPage === Math.ceil(totalItems)}
          >
            {currentPage === Math.ceil(totalItems) ? (
              <svg
                width='9'
                height='15'
                viewBox='0 0 9 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.5 1L7.75 7.25L1.5 13.5'
                  stroke='#707885'
                  strokeWidth='1.2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ) : (
              <svg
                width='9'
                height='15'
                viewBox='0 0 9 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.5 1L7.75 7.25L1.5 13.5'
                  stroke='#4C8D63'
                  strokeWidth='1.2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
