import { baseURL } from '../utils/axios';
import axios from 'axios';

export const createOrder = async (id, data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  };
  const response = await axios.post(
    `${baseURL}/schools/${id}/orders`,
    data,
    config
  );
  return response.data;
};

export const updateOrder = async (id, data, token, orderReference) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  };
  const response = await axios.put(
    `${baseURL}/schools/${id}/orders/${orderReference}`,
    data,
    config
  );
  return response.data;
};
