import React from 'react';

const Identity = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_49_3631)'>
        <path
          d='M20 10C21.8334 10 23.3334 11.5 23.3334 13.3334C23.3334 15.1667 21.8334 16.6667 20 16.6667C18.1667 16.6667 16.6667 15.1667 16.6667 13.3334C16.6667 11.5 18.1667 10 20 10ZM20 25C24.5 25 29.6667 27.15 30 28.3334V30H10V28.35C10.3334 27.15 15.5 25 20 25ZM20 6.66669C16.3167 6.66669 13.3334 9.65002 13.3334 13.3334C13.3334 17.0167 16.3167 20 20 20C23.6834 20 26.6667 17.0167 26.6667 13.3334C26.6667 9.65002 23.6834 6.66669 20 6.66669ZM20 21.6667C15.55 21.6667 6.66669 23.9 6.66669 28.3334V33.3334H33.3334V28.3334C33.3334 23.9 24.45 21.6667 20 21.6667Z'
          fill='#2c2d2c'
        />
      </g>
      <defs>
        <clipPath id='clip0_49_3631'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Identity;
