import React from 'react';

const PersonRemove = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_249_5048)'>
        <path
          d='M9.33333 5.33317C9.33333 3.85984 8.14 2.6665 6.66667 2.6665C5.19333 2.6665 4 3.85984 4 5.33317C4 6.8065 5.19333 7.99984 6.66667 7.99984C8.14 7.99984 9.33333 6.8065 9.33333 5.33317ZM8 5.33317C8 6.0665 7.4 6.6665 6.66667 6.6665C5.93333 6.6665 5.33333 6.0665 5.33333 5.33317C5.33333 4.59984 5.93333 3.99984 6.66667 3.99984C7.4 3.99984 8 4.59984 8 5.33317Z'
          fill='#4B8E64'
        />
        <path
          d='M1.33301 11.9997V13.333H11.9997V11.9997C11.9997 10.2263 8.44634 9.33301 6.66634 9.33301C4.88634 9.33301 1.33301 10.2263 1.33301 11.9997ZM2.66634 11.9997C2.79967 11.5263 4.86634 10.6663 6.66634 10.6663C8.45967 10.6663 10.513 11.5197 10.6663 11.9997H2.66634Z'
          fill='#4B8E64'
        />
        <path d='M15.333 6.6665H11.333V7.99984H15.333V6.6665Z' fill='#4B8E64' />
      </g>
      <defs>
        <clipPath id='clip0_249_5048'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PersonRemove;
