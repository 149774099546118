import React from 'react';

const ChevronRight = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_161_4373)'>
        <path
          d='M9.33344 12L10.2734 11.06L7.2201 8L10.2734 4.94L9.33344 4L5.33344 8L9.33344 12Z'
          fill='#010C15'
          fillOpacity='0.7'
        />
      </g>
      <defs>
        <clipPath id='clip0_161_4373'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(16 16) rotate(180)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronRight;
