import React from 'react';

const Profile = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_249_5056)'>
        <path
          d='M8.00033 3.99984C8.73366 3.99984 9.33366 4.59984 9.33366 5.33317C9.33366 6.0665 8.73366 6.6665 8.00033 6.6665C7.26699 6.6665 6.66699 6.0665 6.66699 5.33317C6.66699 4.59984 7.26699 3.99984 8.00033 3.99984ZM8.00033 10.6665C9.80032 10.6665 11.867 11.5265 12.0003 11.9998H4.00033C4.15366 11.5198 6.20699 10.6665 8.00033 10.6665ZM8.00033 2.6665C6.52699 2.6665 5.33366 3.85984 5.33366 5.33317C5.33366 6.8065 6.52699 7.99984 8.00033 7.99984C9.47366 7.99984 10.667 6.8065 10.667 5.33317C10.667 3.85984 9.47366 2.6665 8.00033 2.6665ZM8.00033 9.33317C6.22033 9.33317 2.66699 10.2265 2.66699 11.9998V13.3332H13.3337V11.9998C13.3337 10.2265 9.78032 9.33317 8.00033 9.33317Z'
          fill='#4B8E64'
        />
      </g>
      <defs>
        <clipPath id='clip0_249_5056'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Profile;
