import { useState } from 'react';
import TextInput from '../../components/Input/TextInput';
import Button from '../../components/Button/Button';
import ArrowForward from '../../icons/ArrowForward';
import { baseURL } from '../../utils/axios';
import { useFetch } from '../../hooks/useFetch';
import { lettersOnly, validateRecords } from '../../utils/utils';

const SchoolInformation = ({ moveToNextStep }) => {
  let lgaUrl = `${baseURL}/states/9/local-government-areas`;
  let zoneUrl = `${baseURL}/states/9/zones`;
  const { data } = useFetch(lgaUrl);
  const zones = useFetch(zoneUrl)?.data?.data?.state?.zones;

  let savedInformation = JSON.parse(localStorage.getItem('information'));
  const [state, setState] = useState({
    schoolName: savedInformation?.schoolName || '',
    schoolType: savedInformation?.schoolType || '',
    schoolLevel: savedInformation?.schoolLevel || '',
    schoolLGA: savedInformation?.schoolLGA || '',
    schoolZone: savedInformation?.schoolZone || '',
    schoolAddress: savedInformation?.schoolAddress || '',
    principalName: savedInformation?.principalName || '',
    headmasterName: savedInformation?.headmasterName || '',
    proprietorName: savedInformation?.proprietorName || '',
  });

  const [errors, setErrors] = useState({
    schoolName: '',
    schoolType: '',
    schoolLevel: '',
    schoolLGA: '',
    schoolZone: '',
    schoolAddress: '',
    principalName: '',
    headmasterName: '',
    proprietorName: '',
  });
  const [searchValue, setSearchValue] = useState('');
  const [validationLoading, setValidationLoading] = useState(false);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleBlur = async (evt) => {
    const value = evt.target.value;

    if (value) {
      setValidationLoading(true);
      let res = await validateRecords({ [evt.target.name]: value }, [
        evt.target.name,
      ]);
      setValidationLoading(false);
      if (res !== 'pass') {
        setValidationLoading(false);
        setErrors({
          ...errors,
          [evt.target.name]: res[0],
        });

        return;
      }
    }

    if (evt.target.dataset.validation === 'textonly') {
      if (lettersOnly(value) === false) {
        setErrors({
          ...errors,
          [evt.target.name]: `Field can only contain letters`,
        });
        return;
      }
    }

    if (!value) {
      setErrors({
        ...errors,
        [evt.target.name]: `Field cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [evt.target.name]: ``,
      });
    }
  };

  const selectValue = (option, name) => {
    setState({ ...state, [name]: option });
  };

  const handleSearch = (evt) => {
    setSearchValue(evt.target.value);
  };

  return (
    <div>
      <TextInput
        label='School Name*'
        fieldClass={errors['schoolName'] ? 'error-field' : 'input-field'}
        placeholder='Enter school name'
        name='schoolName'
        value={state.schoolName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='text'
        errorMessage={errors['schoolName']}
        errorClass={'error-msg'}
      />

      <TextInput
        label='School Type*'
        placeholder='Enter school type'
        name='schoolType'
        value={state.schoolType}
        onSelectValue={selectValue}
        type='dropdown'
        options={[
          { value: 1, label: 'Private School' },
          { value: 2, label: 'Public School' },
        ]}
        errorClass={'error-msg'}
      />

      <TextInput
        label='School Level*'
        placeholder='Enter school type'
        name='schoolLevel'
        value={state.schoolLevel}
        onSelectValue={selectValue}
        type='dropdown'
        options={[
          { value: 'nursery', label: 'Nursery School' },
          { value: 'primary', label: 'Primary School' },
          { value: 'secondary', label: 'Secondary School' },
        ]}
        errorClass={'error-msg'}
      />

      <TextInput
        label='School Local Government Area*'
        placeholder='Select school local governemt area'
        name='schoolLGA'
        value={state.schoolLGA}
        onSelectValue={selectValue}
        searchValue={searchValue}
        handleSearchValue={handleSearch}
        type='dropdown'
        isSearchable
        options={data?.data?.state?.local_government_areas}
        errorClass={'error-msg'}
      />

      <TextInput
        label='School Zone*'
        placeholder='Select school zone'
        name='schoolZone'
        value={state.schoolZone}
        onSelectValue={selectValue}
        type='dropdown'
        options={zones}
        errorClass={'error-msg'}
      />

      <TextInput
        label='School Address*'
        fieldClass={
          errors['schoolAddress'] ? 'textarea-error' : 'textarea-field'
        }
        placeholder='Enter school address'
        name='schoolAddress'
        value={state.schoolAddress}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type='textarea'
        errorMessage={errors['schoolAddress']}
        errorClass={'error-msg'}
      />

      <TextInput
        label='Name of Principal'
        fieldClass={errors['principalName'] ? 'error-field' : 'input-field'}
        placeholder='Enter name of principal'
        name='principalName'
        value={state.principalName}
        handleBlur={handleBlur}
        handleChange={handleChange}
        errorMessage={errors['principalName']}
        type='text'
        errorClass={'error-msg'}
        validationType={'textonly'}
      />

      <TextInput
        label='Name of Headmaster'
        fieldClass={errors['headmasterName'] ? 'error-field' : 'input-field'}
        placeholder='Enter name of headmaster'
        name='headmasterName'
        value={state.headmasterName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errorMessage={errors['headmasterName']}
        type='text'
        errorClass={'error-msg'}
        validationType={'textonly'}
      />

      <TextInput
        label='Name of Proprietor'
        fieldClass={'input-field'}
        placeholder='Enter name of proprietor'
        name='proprietorName'
        value={state.proprietorName}
        handleChange={handleChange}
        type='text'
        errorClass={'error-msg'}
        validationType={'textonly'}
      />

      <Button
        btnClass={'reg-button'}
        btnText={
          <div className='btn-flex'>
            <p>{validationLoading ? 'Please wait..' : 'Proceed'}</p>
            <ArrowForward />
          </div>
        }
        onClick={() => {
          localStorage.setItem('information', JSON.stringify(state));
          moveToNextStep('contact');
        }}
        disabled={
          state.schoolName === '' ||
          state.schoolType === '' ||
          state.schoolLevel === '' ||
          state.schoolLGA === '' ||
          state.schoolZone === '' ||
          state.schoolAddress === '' ||
          state.principalName === '' ||
          state.headmasterName === '' ||
          state.proprietorName === '' ||
          errors.schoolName ||
          errors.principalName ||
          errors.headmasterName ||
          errors.proprietorName
        }
      />
    </div>
  );
};

export default SchoolInformation;
