import * as actionTypes from '../actions/actionTypes';

const initialState = {
  modalOpen: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_AUTH_MODAL:
      return {
        modalOpen: action.payload.data,
      };

    case actionTypes.CLOSE_AUTH_MODAL:
      return {
        modalOpen: action.payload.data,
      };

    default:
      return state;
  }
};

export default modalReducer;
