import React, { useRef } from 'react';
import Modal from 'react-modal';
import { Cancel } from '../../../icons/Cancel';
import atoImage from '../../../assets/ato.png';
import './atomodal.scss';
import Button from '../../Button/Button';
import { useReactToPrint } from 'react-to-print';

const ViewAtoModal = ({ modalOpen, closeModal, schoolName }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '600px',
    },
  };
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  return (
    <Modal
      isOpen={modalOpen}
      style={customStyles}
      onRequestClose={closeModal}
      contentLabel='Example Modal'
    >
      <div className='modal-cancel' onClick={() => closeModal()}>
        <Cancel />
      </div>
      <div className='ato-modal' ref={ref}>
        <p>{schoolName}</p>
        <img src={atoImage} alt='' />
      </div>
      <Button
        btnClass={'reg-button'}
        btnText={'Download ATO'}
        onClick={() => handlePrint()}
      />
    </Modal>
  );
};

export default ViewAtoModal;
