import * as services from '../../services/paymentService';
import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';

//auth dispatch
export const createStart = () => {
  return {
    type: actionTypes.CREATE_ORDER_START,
  };
};

export const createFail = (error) => {
  return {
    type: actionTypes.CREATE_ORDER_FAIL,
    payload: {
      error,
    },
  };
};

export const createSuccess = (data) => {
  return {
    type: actionTypes.CREATE_ORDER_SUCCESS,
    payload: {
      data,
    },
  };
};

//actions
export const createOrder = (id, data, token, callback) => {
  return async (dispatch) => {
    dispatch(createStart());
    try {
      const response = await services.createOrder(id, data, token);
      dispatch(createSuccess(response.data.order));
      callback();
    } catch (error) {
      toast.error(error.response.data.data.error);
      dispatch(createFail(error.response.data.data.error));
    }
  };
};

export const updateStart = () => {
  return {
    type: actionTypes.UPDATE_ORDER_START,
  };
};

export const updateFail = (error) => {
  return {
    type: actionTypes.UPDATE_ORDER_FAIL,
    payload: {
      error,
    },
  };
};

export const updateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_ORDER_SUCCESS,
    payload: {
      data,
    },
  };
};

//actions
export const updateOrder = (id, data, token, orderReference, callback) => {
  return async (dispatch) => {
    dispatch(updateStart());
    const updateUserLocalStorage = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      const newObj = { ...user?.school, ...{ activated: true } };
      let objToBeSaved = Object.assign(user?.school, newObj);
      localStorage.setItem('user', JSON.stringify(user));
    };
    try {
      const response = await services.updateOrder(
        id,
        data,
        token,
        orderReference
      );
      dispatch(updateSuccess(response.data.order));
      toast.success('Order updated successfully');
      updateUserLocalStorage();

      callback();
    } catch (error) {
      dispatch(updateFail(error?.response?.data?.data?.error));
      toast.error(error.response.data.data.error);
    }
  };
};
