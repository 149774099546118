import React from 'react';

export const Cancel = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_161_4325)'>
        <path
          d='M12.6668 4.2735L11.7268 3.3335L8.00016 7.06016L4.2735 3.3335L3.3335 4.2735L7.06016 8.00016L3.3335 11.7268L4.2735 12.6668L8.00016 8.94016L11.7268 12.6668L12.6668 11.7268L8.94016 8.00016L12.6668 4.2735Z'
          fill='#D80027'
        />
      </g>
      <defs>
        <clipPath id='clip0_161_4325'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
